import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { companiesStatus } from '../../utils/constants/tagColors';

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const { name, status, edi_code, address, phone_number, website } = data;

  return [
    {
      label: 'insurance_companies.show.name',
      span: 3,
      content: name || '-'
    },
    {
      label: 'insurance_companies.show.status',
      span: 1,
      content:
        (status && (
          <Tag color={companiesStatus[status]}>
            {t(`insurance_companies.form.statuses.${status}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'insurance_companies.show.edi_code',
      span: 1,
      content: edi_code || '-'
    },
    {
      label: 'insurance_companies.show.phone_number_full',
      span: 1,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'insurance_companies.show.address_full',
      span: 3,
      content:
        address && address.number !== '' ? (
          <>
            {address.number} {address.street} {address.additional && <br />}
            {address.postal_code} {address.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: 'insurance_companies.show.website',
      span: 3,
      content:
        (
          <a href={website} target="_blank" rel="noreferrer">
            {website}
          </a>
        ) || '-'
    }
  ];
};
