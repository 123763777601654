import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Segmented, Input, Button, Switch, DatePicker, Flex } from 'antd';
import {
  PlusOutlined,
  TableOutlined,
  ProjectOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { StarFilled } from '@carbon/icons-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSuccessMessage } from '../../utils/successMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { CreateUpdateDealDrawer } from './CreateUpdateDeal/CreateUpdateDealDrawer';
import { DealsGrid } from './DealsGrid';
import { KanbanBoard } from './Kanban/KanbanBoard';
import { ListDeals } from './List/ListDeals';
import { dateFormat } from '../../utils/formatters';
import { getFilters } from './getRequestFilters';

const { Search } = Input;
const { RangePicker } = DatePicker;

/**
 * Deals component
 * @component
 * @returns {JSX.Element} Deals component
 */
export const Deals = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const { pathname } = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const [keywords, setKeywords] = useState(keyword);
  const [drawer, setDrawer] = useState(null);
  const [content, setContent] = useState('kanban');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filters, setFilters] = useState({
    archived: false,
    range_date: null,
    favorites: false,
    deal_manager: false
  });

  const searchResource = (value) => {
    if (value) {
      const encodedValue = encodeURIComponent(value);

      navigate({
        pathname,
        search: `&k=${encodedValue}`
      });
    } else {
      navigate({
        pathname
      });
    }
  };
  const archiveDeal = async (_id) => {
    try {
      await dispatchAPI('DELETE', { url: `/deals/${_id}` });

      successMessage('deal_archived');
      setForceRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    }
  };

  const handleOpenDrawer = () => {
    const modalKey = Date.now();
    setDrawer(
      <CreateUpdateDealDrawer
        key={modalKey}
        purpose="create"
        setForceRefresh={setForceRefresh}
      />
    );
  };

  const handleEditDeal = (deal) => {
    const drawerKey = Date.now();
    setDrawer(
      <CreateUpdateDealDrawer
        key={drawerKey}
        purpose="edit"
        initialValues={deal}
        setForceRefresh={setForceRefresh}
      />
    );
  };

  useEffect(() => {
    setKeywords(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setKeywords(keyword);
    else setKeywords(null);
  }, [keyword]);

  const segmentedOptions = [
    {
      label: t('buttons.kanban'),
      value: 'kanban',
      icon: <ProjectOutlined />
    },
    {
      label: t('buttons.table'),
      value: 'list',
      icon: <TableOutlined />
    }
  ];

  const components = {
    kanban: (
      <KanbanBoard
        handleEditDeal={handleEditDeal}
        archiveDeal={archiveDeal}
        keywords={keywords}
        extraQuery={getFilters(filters)}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
    ),
    list: (
      <ListDeals
        handleEditDeal={handleEditDeal}
        archiveDeal={archiveDeal}
        extraQuery={getFilters(filters)}
        forceRefresh={forceRefresh}
      />
    )
  };

  return (
    <>
      {drawer}
      <PageHeaderCustom
        title={t('deals.title')}
        extra={
          <Segmented
            onChange={(value) => setContent(value)}
            options={segmentedOptions}
          />
        }
      />
      <ContentCustom>
        <DealsGrid forceRefresh={forceRefresh} />
        <Flex
          justify="space-between"
          wrap
          gap="small"
          style={{ width: '100%' }}
        >
          <Flex gap="small" align="center">
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={keywords}
              onSearch={(value) => searchResource(value)}
            />
            <RangePicker
              style={{ minWidth: 300 }}
              format={dateFormat}
              onChange={(value) =>
                setFilters({ ...filters, range_date: value })
              }
            />
          </Flex>
          <Flex gap="middle" align="center">
            {content === 'kanban' && (
              <>
                <Switch
                  checkedChildren={t('deals.me_only')}
                  unCheckedChildren={t('deals.me_only')}
                  onChange={(value) =>
                    setFilters({ ...filters, deal_manager: value })
                  }
                />
                <Flex gap="6px" align="center">
                  <StarFilled />
                  {t('deals.favorites_only')}
                  <Switch
                    onChange={(value) =>
                      setFilters({ ...filters, favorites: value })
                    }
                  />
                </Flex>
                <Flex gap="6px" align="center">
                  <ContainerOutlined />
                  {t('deals.display_archived')}
                  <Switch
                    onChange={(value) =>
                      setFilters({ ...filters, archived: value })
                    }
                  />
                </Flex>
              </>
            )}
            <Button type="primary" onClick={() => handleOpenDrawer()}>
              {t('buttons.create')} <PlusOutlined />
            </Button>
          </Flex>
        </Flex>
        {components[content]}
      </ContentCustom>
    </>
  );
};
