import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { routes } from '../../../utils/constants/userRoutes';
import {
  getCustomerName,
  getFullName,
  getPhoneNumber,
  getFormattedDate,
  getFormattedAmount
} from '../../../utils/formatters';

const iconSize = 18;

/**
 * Hook to get columns for the deals list
 * @hook
 * @param {function} handleEditDeal - function to handle edit deal
 * @param {function} archiveDeal - function to archive deal
 * @returns {Array} columns for the deals list
 */
export const useColumns = (handleEditDeal, archiveDeal) => {
  const translationKey = 'deals.form';
  const { t } = useTranslation();

  return [
    {
      title: t(`${translationKey}.name`),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t(`${translationKey}.customer`),
      key: 'customer',
      dataIndex: 'customer',
      sorter: true,
      render: (customer) => getCustomerName(customer)
    },
    {
      title: t(`${translationKey}.stage`),
      key: 'stage',
      dataIndex: 'stage',
      sorter: true,
      render: (stage) => t(`${translationKey}.stages.${stage}`)
    },
    {
      title: t(`${translationKey}.amount`),
      key: 'amount',
      dataIndex: 'amount',
      sorter: true,
      render: (amount) => getFormattedAmount(amount)
    },
    {
      title: t(`${translationKey}.principal_contact`),
      key: 'principal_contact',
      dataIndex: 'principal_contact',
      sorter: true,
      render: (principal_contact) => getFullName(principal_contact)
    },
    {
      title: t(`${translationKey}.email`),
      key: 'email',
      dataIndex: 'principal_contact',
      render: (principal_contact) =>
        (
          <a href={`mailto:${principal_contact?.email}`}>
            {principal_contact?.email}
          </a>
        ) || '-'
    },
    {
      title: t(`${translationKey}.phone_number`),
      key: 'phone_number',
      dataIndex: 'principal_contact',
      render: (principal_contact) =>
        getPhoneNumber(principal_contact?.phone_number)
    },
    {
      title: t(`${translationKey}.last_update`),
      key: 'updated_at',
      dataIndex: 'updated_at',
      render: (updated_at) => getFormattedDate(updated_at)
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${routes.DEALS}/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <EditOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              handleEditDeal(record);
            }}
          />
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() => archiveDeal(record?._id)}
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: 'var(--errorColor)', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
