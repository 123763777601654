import { Form, Input, Select, Checkbox, Button, Radio } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useInsuranceCompanyContext from './InsuranceCompanyContext';

const { Option } = Select;

/**
 * Hook to get contact fields
 * @hook
 * @returns {object} contact fields
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { contactEnums, isFieldsLoading, idCompany, purpose } =
    useInsuranceCompanyContext();

  const fields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['position'],
      input: (
        <Select loading={isFieldsLoading}>
          {(contactEnums?.positions || []).map((position) => (
            <Option key={position} value={position}>
              {t(`contacts.enums.${position}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['is_principal_contact'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['effective_beneficiary'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['email'],
      key: 'email',
      rules: [{ required: true }, { type: 'email' }],
      input: <Input type="email" disabled={purpose === 'edit'} />
    },
    {
      name: ['phone_numbers'],
      input: (
        <Form.List name="phone_numbers" initialValue={[{}]}>
          {(phone_numbers, { add, remove }) => (
            <>
              {phone_numbers.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'phone_number']}
                    name={[field.name, 'phone_number']}
                    noLabel
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'country_code']}
                        initialValue="+33"
                      >
                        <Select>
                          <Option value="+33">+33</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item noStyle name={[field?.name, 'number']}>
                        <Input />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '-20px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('contacts.form.extra.add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['politically_exposed_person'],
      rules: [{ required: true }],
      initialValue: false,
      input: (
        <Radio.Group loading={isFieldsLoading}>
          {[true, false].map((bool) => (
            <Radio key={bool} value={bool}>
              {t(`contacts.enums.${bool}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['company'],
      initialValue: idCompany,
      hidden: true,
      rules: [{ required: true }]
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
