import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Modal, Form, Input, Button, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Modal to add a new deal origin
 * @param {function} setForceRefresh function to refresh the page
 * @param {string} mainOriginId id of the main origin
 * @returns {JSX.Element} Modal to add a new deal origin
 */
export const ModalAddDealOrigin = ({ setForceRefresh, mainOriginId }) => {
  const translationKey = 'deals_origin.form';
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const [open, setOpen] = useState(true);

  const onFinish = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: `/deal-origins/${mainOriginId}/sub-origins`,
        body: {
          ...values,
          agency
        }
      });

      setOpen(false);
      setForceRefresh((prevState) => !prevState);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Modal
      title={t(`${translationKey}.title`)}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="label"
          label={t(`${translationKey}.origin_detail`)}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Flex justify="end" gap="small">
            <Button type="link" danger onClick={() => setOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddDealOrigin.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  mainOriginId: PropTypes.string.isRequired
};
