import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Card } from 'antd';
import {
  CurrencyEuro,
  Rocket,
  Asleep,
  Hourglass,
  CloseOutline,
  Trophy
} from '@carbon/icons-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * DealsGrid component
 * @component
 * @param {boolean} forceRefresh - force refresh state
 * @returns {JSX.Element} DealsGrid component
 */
export const DealsGrid = ({ forceRefresh }) => {
  const translationKey = 'deals.grid';
  const iconSize = 32;
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const [gridData, setGridData] = useState([]);

  const icons = {
    total_amount: <CurrencyEuro size={iconSize} />,
    total_amount_staged: <CurrencyEuro size={iconSize} />,
    ongoing_opportunities: <Rocket size={iconSize} />,
    inactives: <Asleep size={iconSize} />,
    lates: <Hourglass size={iconSize} />,
    deals_lost: <CloseOutline size={iconSize} />,
    deals_won_this_month: <Trophy size={iconSize} />
  };

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/grid?agency=${agency}&archived=false`
      });
      setGridData(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [agency, forceRefresh]);

  return (
    <List
      grid={{
        gutter: 10,
        xs: 2,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 7
      }}
      dataSource={gridData}
      renderItem={(item) => (
        <List.Item>
          <Card
            cover={icons[item.content]}
            className={
              item.content === 'lates' ? 'deal-stats warning' : 'deal-stats'
            }
          >
            <p>{item.title}</p>
            {t(`${translationKey}.titles.${item.content}`)}
          </Card>
        </List.Item>
      )}
    />
  );
};

DealsGrid.propTypes = {
  forceRefresh: PropTypes.bool.isRequired
};
