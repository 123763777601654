import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Flex, Spin } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import { useFields } from './fields';

/**
 * Component that renders the customer form.
 *
 * @component
 * @param {Function} handleNextStep - The function to handle the next step.
 * @param {Object} initialValues - The initial values of the form.
 * @param {string} purpose - The purpose of the form.
 * @param {boolean} loading - The loading state of the form.
 * @returns {JSX.Element} - The customer form component.
 */
export const CustomerForm = ({
  handleNextStep,
  initialValues,
  purpose,
  loading
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();

  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, purpose]);

  return (
    <Spin spinning={loading}>
      <Form onFinish={handleNextStep} {...formItemLayout} form={form}>
        {fields.map((field) => generateFields('customers', field))}
        <Flex justify="center" gap="small">
          <Button type="primary" htmlType="submit">
            {t('buttons.next')}
            <ArrowRightOutlined />
          </Button>
        </Flex>
      </Form>
    </Spin>
  );
};

CustomerForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  purpose: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

CustomerForm.defaultProps = {
  initialValues: {},
  loading: false
};
