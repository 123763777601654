import { createGlobalStyle } from 'styled-components';

const DarkTheme = createGlobalStyle`
  body {
    color-scheme: dark;
    --clientColor: #030407;
    --primaryColor: #13277c;
    --addColor: #47d267;
    --componentBackground: #0a0c14;
    --secondaryColor: #617EFF;
    --disabledColor: #636363;
    --bodyBackground: var(--clientColor);
    --primaryHover: #181b30;
    --secondaryHover: #fff;
    --textColor: rgba(255, 255, 255, 0.8);
    --textColorSecondary: #bfbfbf;
    --textColorActive: var(--secondaryColor);
    --textColorInvert: #181b30;
    --itemHoverBackground: var(--primaryHover);
    --itemActiveBackground: var(--primaryHover);
    --borderColor: #38414a;
    --borderColorSecondary: #1d2126;
    --contrastBackground: #111322;
    --errorColor: #e31919;
    --boxShadowColor: rgba(0, 0, 0, 0.8);
    --boxShadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    --borderRadius: 4px;
    --borderRadiusXS: 2px;
    --borderRadiusLG: 8px;
  }
`;

export default DarkTheme;
