import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Upload,
  message as antdMessage,
  Popconfirm
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  CheckOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Dragger } = Upload;

/**
 * `FilesManagerShow` is a React component for managing a list of files. It allows for file deletion, viewing,
 * downloading, and type changing. It uses a custom Dragger component for file uploads.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {Array} props.filesList - Array of file objects.
 * @param {Function} props.setFilesList - Function to set the new file list.
 * @param {Array} props.filesKeys - Array of file keys.
 * @param {Array} props.filesConfiguration - The current configuration of the files.
 * @param {Function} props.setFilesConfiguration - Function to set the new files configuration.
 * @param {string} props.purpose - The purpose of the file usage.
 * @param {Function} props.deleteFile - Function to delete a specific file.
 * @returns {JSX.Element} A JSX element representing the component.
 */
const FilesManagerShow = ({
  resourceName,
  tradKey,
  fileKeys,
  fileLists,
  setFileLists,
  resource,
  getResource
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFiles = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      Object.keys(fileLists).forEach((key) => {
        fileLists[key].forEach((file) => {
          formData.append(key, file);
        });
      });
      await dispatchAPI('PATCH', {
        url: `${resourceName}/upload/${resource._id}`,
        body: formData
      });
      setFileLists({});
      getResource();
      antdMessage.success(t('success.message.downloads'));
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteFile = async (fileID, fileKey) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${resource._id}/${fileID}/${fileKey}`
      });
      getResource();
    } catch (e) {
      message(e);
    }
  };

  const draggerProps = (fileKey) => ({
    multiple: true,
    onRemove: (file) => {
      const newFileList = fileLists[fileKey].filter((f) => f.uid !== file.uid);
      setFileLists((prevState) => ({
        ...prevState,
        [fileKey]: newFileList
      }));
    },
    beforeUpload: (file) => {
      const newFileList = [...(fileLists[fileKey] || []), file];
      setFileLists((prevState) => ({
        ...prevState,
        [fileKey]: newFileList
      }));
      return false;
    },
    fileList: fileLists[fileKey] || []
  });

  return (
    <Card title={t('files.title')} className="file-manager-card">
      <Flex vertical className="file-manager-wrapper">
        {(fileKeys || []).map((fileKey) => (
          <Row key={fileKey} className="file-manager-row">
            <Col xs={24} xl={6}>
              {t(`${tradKey}.form.${fileKey}`)}
            </Col>
            <Col xs={24} xl={9}>
              <Flex wrap gap="small" align="center">
                {(resource?.[fileKey] || []).map((file) => (
                  <Flex
                    key={file?._id}
                    className="file-manager-item"
                    align="center"
                    gap="middle"
                  >
                    <span className="file-name">
                      {file?.metadata?.originalName}
                    </span>
                    <Flex>
                      <Button
                        onClick={() => viewDocument(file)}
                        type="link"
                        size="small"
                        icon={<EyeOutlined />}
                      />
                      <Button
                        onClick={() => downloadDocument(file)}
                        type="link"
                        size="small"
                        icon={<DownloadOutlined />}
                      />
                      <Popconfirm
                        title={t('datatable.column.action.delete.title')}
                        okText={t('datatable.column.action.delete.ok')}
                        okButtonProps={{ type: 'danger' }}
                        cancelText={t('datatable.column.action.delete.cancel')}
                        onConfirm={() => deleteFile(file?._id, fileKey)}
                        icon={<WarningOutlined />}
                      >
                        <Button
                          type="link"
                          size="small"
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Col>
            <Col xs={24} xl={9}>
              <Flex wrap gap="middle" align="center">
                <Dragger
                  {...draggerProps(fileKey)}
                  style={{
                    border: 'none',
                    background: 'none'
                  }}
                  showUploadList={false}
                >
                  <Button icon={<DownloadOutlined />}>
                    {t('files.button.upload')}
                  </Button>
                </Dragger>

                <Flex gap="small">
                  {(fileLists[fileKey] || []).map((file) => (
                    <Flex
                      key={file?._id}
                      className="file-manager-item"
                      align="center"
                      gap="middle"
                    >
                      <span className="file-name">{file.name}</span>
                      {/* Add delete button to remove file from state here */}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Col>
          </Row>
        ))}
      </Flex>
      <Button
        id="save-button"
        type="primary"
        loading={isSubmitting}
        disabled={isSubmitting || Object.keys(fileLists).length === 0}
        onClick={submitFiles}
      >
        {`${t('buttons.save')} `}
        <CheckOutlined />
      </Button>
    </Card>
  );
};

FilesManagerShow.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  fileKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileLists: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  setFileLists: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  getResource: PropTypes.func.isRequired
};

export default FilesManagerShow;
