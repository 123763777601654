import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Col, Row } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useMenuItems } from './menuItems';

/**
 * Agency Configuration
 * @returns {JSX.Element} Configuration Component
 */
export const Configuration = () => {
  const { t } = useTranslation();
  const { items } = useMenuItems();
  const [selectedKey, setSelectedKey] = useState('agency_configuration');

  return (
    <>
      <PageHeaderCustom title={t('configuration.show.title')} />
      <ContentCustom>
        <Row gutter={48}>
          <Col xs={24} lg={10} xl={6} xxl={5}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['agency_configuration']}
              items={items}
              onClick={({ key }) => {
                setSelectedKey(key);
              }}
              className="configuration-menu"
            />
          </Col>
          <Col xs={24} lg={14} xl={18} xxl={19}>
            {items.find((item) => item.key === selectedKey)?.component}
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
