import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Drawer } from 'antd';
import { CreateUpdateContainerWithFileManager } from '../../../../components';
import { useFields } from './fields';

/**
 * Create or update a consultation
 * @component
 * @prop {string} purpose - Purpose of the component (create or edit)
 * @prop {function} setForceRefresh - function to set force refresh
 * @prop {string} resourceId - The id of the resource being created or edited.
 * @returns {JSX.Element} The form to create or update a consultation
 */
export const CreateUpdateConsultation = ({
  purpose,
  setForceRefresh,
  resourceId
}) => {
  const translationKey = 'consultations.form';
  const { id } = useParams();
  const { t } = useTranslation();
  const { fields } = useFields();
  const [open, setOpen] = useState(true);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        deal: id
      })
    },
    onGetResource: {
      setFields: (data) => ({
        ...data,
        entry_date: data.entry_date && dayjs(data.entry_date),
        submitted_to_company_date:
          data.submitted_to_company_date &&
          dayjs(data.submitted_to_company_date),
        company_response_date:
          data.company_response_date && dayjs(data.company_response_date),
        expiry_date: data.expiry_date && dayjs(data.expiry_date)
      })
    }
  };

  const customFinalAction = () => {
    setForceRefresh((prevState) => !prevState);
    setOpen(false);
  };

  return (
    <Drawer
      title={t(`${translationKey}.titles.${purpose}`)}
      open={open}
      onClose={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <CreateUpdateContainerWithFileManager
        fields={fields}
        config={config}
        resourceId={resourceId}
        customFinalAction={customFinalAction}
        customCancel={() => setOpen(false)}
        purpose={purpose}
        baseUrl="consultations"
        resource="consultations"
        withPageHeader={false}
        formDirection="vertical"
        contentCustomStyle={{
          padding: 0,
          background: 'var(--componentBackground)'
        }}
      />
    </Drawer>
  );
};

CreateUpdateConsultation.propTypes = {
  purpose: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  resourceId: PropTypes.string
};

CreateUpdateConsultation.defaultProps = {
  resourceId: undefined
};
