import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Returns the filters for the API request
 *
 * @function
 * @param {object} filters - filters
 * @param {string} filters.agency - current agency id
 * @param {string} filters.user - current user
 * @param {array} filters.range_date - date range
 * @param {boolean} filters.archived - if the archived switch is on or off
 * @param {boolean} filters.favorites - if the user wants to see only his favorites
 * @param {boolean} filters.deal_manager - if the user wants to see only the deals he manages
 * @returns {string} filters - filters for the API request
 */
export const getFilters = (filters) => {
  const { agency, user } = useAuthContext();
  const { range_date, archived, favorites, deal_manager } = filters;

  return `agency=${agency}&populate=customer,principal_contact${
    !archived ? '&archived=false' : '&archived=true'
  }${favorites ? `&favorites=${user?._id}` : ''}${
    deal_manager ? `&deal_manager=${user?._id}` : ''
  }${
    range_date
      ? `&filter=${encodeURIComponent(
          JSON.stringify({
            created_at: {
              $gte: range_date[0],
              $lte: range_date[1]
            }
          })
        )}`
      : ''
  }`;
};
