import {
  Dashboard,
  Calendar,
  LicenseThirdParty,
  Rocket,
  Store,
  Bullhorn,
  Settings,
  BusinessProcesses,
  UserMultiple,
  GroupAccess
} from '@carbon/icons-react';

const navMenuLogos = {
  HOME: <Dashboard />,
  MEETINGS: <Calendar />,
  CUSTOMERS: <LicenseThirdParty />,
  DEALS: <Rocket />,
  INSURANCE_COMPANIES: <Store />,
  CONTACTS: <Bullhorn />,
  CONFIGURATION: <Settings />,
  AGENCIES: <BusinessProcesses />,
  USERS: <UserMultiple />,
  ACCESS_RIGHTS: <GroupAccess />
};

export default navMenuLogos;
