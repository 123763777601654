import { Link } from 'react-router-dom';
import { Flex, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Launch,
  CloseOutline,
  CheckmarkOutline,
  Email
} from '@carbon/icons-react';
import { routes } from '../../../utils/constants/userRoutes';
import {
  getCustomerName,
  getFullName,
  getPhoneNumber,
  getFormattedDate,
  getFormattedAmount
} from '../../../utils/formatters';

/**
 * Hook to get deals list content
 * @hook
 * @param {object} data - data to use
 * @returns {object} deals list content
 */
export const useListContent = (data = {}) => {
  const translationKey = 'deals.form';
  const { t } = useTranslation();
  const {
    customer,
    deal_manager,
    internal_deadline,
    customer_deadline,
    amount,
    replacement_order,
    deal_origin,
    description,
    decision_maker
  } = data;

  return [
    {
      label: `${translationKey}.customer`,
      span: 1,
      content: (
        <Flex align="center" gap="small">
          <Launch />
          <Link to={`${routes.CUSTOMERS}/show/${customer?._id}`}>
            {getCustomerName(customer)}
          </Link>
        </Flex>
      )
    },
    {
      label: `${translationKey}.deal_manager`,
      span: 1,
      content: getFullName(deal_manager)
    },
    {
      label: `${translationKey}.internal_deadline`,
      span: 1,
      content: <Tag color="green">{getFormattedDate(internal_deadline)}</Tag>
    },
    {
      label: `${translationKey}.customer_deadline`,
      span: 1,
      content: <Tag color="green">{getFormattedDate(customer_deadline)}</Tag>
    },
    {
      label: `${translationKey}.amount`,
      span: 1,
      content: getFormattedAmount(amount)
    },
    {
      label: `${translationKey}.replacement_order`,
      span: 1,
      content: replacement_order ? (
        <Flex align="center" gap="small">
          <CheckmarkOutline />
          {t('yes')}
        </Flex>
      ) : (
        <Flex align="center" gap="small">
          <CloseOutline style={{ color: 'var(--disabledColor)' }} />
          {t('no')}
        </Flex>
      )
    },
    {
      label: `${translationKey}.deal_origin`,
      span: 2,
      content: deal_origin?.label || '-'
    },
    {
      label: `${translationKey}.description`,
      span: 4,
      content: description || '-'
    },
    ...(customer?.type === 'ENTITY'
      ? [
          {
            label: `${translationKey}.decision_maker`,
            span: 1,
            content: getFullName(decision_maker)
          },
          {
            label: `${translationKey}.decision_maker_email`,
            span: 2,
            content:
              (
                <Flex align="center" gap="small">
                  <Email />
                  <a href={`mailto:${decision_maker?.email}`}>
                    {decision_maker?.email}
                  </a>
                </Flex>
              ) || '-'
          },
          {
            label: `${translationKey}.decision_maker_phone_number`,
            span: 2,
            content: getPhoneNumber(decision_maker?.phone_number)
          }
        ]
      : [])
  ];
};
