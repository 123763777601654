import PropTypes from 'prop-types';
import { Datatable } from '../../../components';
import { useColumns } from './columns';

/**
 * ListDeals component
 * @component
 * @param {string} extraQuery - extra query filters
 * @param {boolean} forceRefresh - force refresh
 * @param {function} handleEditDeal - function to handle edit deal
 * @param {function} archiveDeal - function to archive deal
 * @returns {JSX.Element} ListDeals component
 */
export const ListDeals = ({
  extraQuery,
  forceRefresh,
  handleEditDeal,
  archiveDeal
}) => {
  const columns = useColumns(handleEditDeal, archiveDeal);

  return (
    <Datatable
      resourceName="deals"
      extraQuery={extraQuery}
      columns={columns}
      forceRefresh={forceRefresh}
      customActionColumn
      style={{ marginTop: 16 }}
      scroll={{ x: 1400 }}
    />
  );
};

ListDeals.propTypes = {
  extraQuery: PropTypes.string.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  handleEditDeal: PropTypes.func.isRequired,
  archiveDeal: PropTypes.func.isRequired
};
