import { Route, Routes } from 'react-router-dom';
import { ListCustomers } from './ListCustomers';
import { CreateUpdateCustomer } from './CreateUpdateCustomer';
import { ShowCustomer } from './show/ShowCustomer';
import { Exception } from '../../components';

/**
 * Customer router
 *
 * Available routes:
 * `/create` create a new customer
 * `/edit/:id` edit an existing customer
 * `/show/:id` show a customer
 * `/` list all customers
 * `*` display an error page
 *
 * @returns {JSX.Element} Component to display the customers
 */
export const CustomerRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateCustomer purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateCustomer purpose="edit" />} />
    <Route path="/show/:id" element={<ShowCustomer />} />
    <Route index element={<ListCustomers />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
