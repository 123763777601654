import dayjs from 'dayjs';

/**
 * The date format used in the application.
 * @constant {string}
 */
export const dateFormat = 'DD-MM-YYYY';

/**
 * Represents the date format with time.
 * @constant {string}
 */
export const dateFormatWithTime = 'DD-MM-YYYY H[h]mm';

/**
 * Formats the price value with 2 decimal places and adds the euro symbol (€).
 * If the price is falsy, returns a dash (-) instead.
 *
 * @param {number} price - The price value to format. (ex. 1200)
 * @returns {string} The formatted price string. (ex. '1 200,00€') or '-' if no price is provided.
 */
export const getPrice = (price) => {
  if (!price) return '-';
  return `${price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}€`;
};

/**
 * Capitalizes the first letter of a string and converts the rest to lowercase.
 *
 * @function
 * @param {string} str - The string to capitalize. (ex. 'john')
 * @returns {string} The capitalized string. (ex. 'John')
 */
const capitalize = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Returns the formatted address string by combining the address components.
 *
 * @function
 * @param {Object} address - The address object.
 * @param {string} address.number - The street number. (optional) (ex. '12')
 * @param {string} address.street - The street name. (required) (ex. 'Rue de la Paix')
 * @param {string} address.additional - Additional address information (optional). (ex. 'Bâtiment A')
 * @param {string} address.postal_code - The postal code. (required) (ex. '75000')
 * @param {string} address.city - The city. (required) (ex. 'Paris')
 * @returns {string} The formatted address string. (ex. '12, Rue de la Paix, Bâtiment A, 75000 Paris, France') or '-' if no address is provided.
 */
export const getAddress = (address) => {
  if (!address || typeof address !== 'object') {
    return '-';
  }
  const { number, street, additional, postal_code, city, country } = address;
  if (!street || !postal_code || !city) return '-';

  const addressString = `${number ? `${number}, ` : ''}${street}, ${
    additional ? `${additional} ,` : ''
  }${postal_code} ${city}${country ? `, ${country}` : ''}`;

  return addressString.trim();
};

/**
 * Returns the formatted phone number by concatenating the country code and number.
 *
 * @function
 * @param {Object} phoneNumber - The phone number object. (ex. { country_code: '+33', number: '0612345678' })
 * @param {string} phoneNumber.country_code - The country code. (ex. '+33')
 * @param {string} phoneNumber.number - The phone number. (ex. '0612345678')
 * @returns {string} The formatted phone number. (ex. '+33 6 12 34 56 78') or '-' if no phone number is provided.
 */
export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'object') return '-';
  const { country_code = null, number = null } = phoneNumber;
  if (!country_code || !number) return '-';

  let modifiedNumber;
  let formattedNumber;

  switch (country_code) {
    case '+33':
      // Remove the first digit of the number to return '6 12 34 56 78' format.
      modifiedNumber = number.slice(1);
      formattedNumber = modifiedNumber.replace(
        /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
        '$1 $2 $3 $4 $5'
      );
      return `${country_code} ${formattedNumber}`;
    default:
      return '-';
  }
};

/**
 * Returns the formatted birth date.
 *
 * @function
 * @param {string} date - The birth date. (ex. '1995-11-03T23:00:00.000Z')
 * @returns {string} The formatted birth date. (ex. '04-11-1995') or '-' if no date is provided.
 */
export const getBirthDate = (date) => {
  if (!date) return '-';
  return dayjs(date).format('DD-MM-YYYY');
};

/**
 * Returns the full name by concatenating the first name and last name.
 *
 * @function
 * @param {Object} person - The person object. (ex. { first_name: 'John', last_name: 'Doe' })
 * @param {string} prefix - The prefix to be added to the name. (ex. 'Dr.')
 * @returns {string} The full name. (ex. 'John DOE') or '-' if no name is provided.
 */
export const getFullName = (person, prefix) => {
  if (!person || typeof person !== 'object') return '-';
  if (!person?.first_name && !person?.last_name) return '-';

  const { first_name, last_name } = person;

  const formattedFirstName = first_name ? capitalize(first_name) : '';
  const formattedLastName = last_name ? last_name.toUpperCase() : '';
  const formattedPrefix = prefix ? `${prefix} ` : '';

  return `${formattedPrefix}${formattedLastName} ${formattedFirstName}`;
};

/**
 * Returns the customer name based on the type of the customer.
 * @function
 *
 * @param {*} customer - The customer object.
 * @returns {string} The customer name. (ex. 'DOE John' or 'Company Inc.') or '-' if no customer is provided.
 */
export const getCustomerName = (customer) => {
  if (!customer || typeof customer !== 'object') return '-';
  if (customer?.type === 'ENTITY') {
    return customer.name || '-';
  }
  return getFullName(customer);
};

/**
 * Returns the initials of a user.
 * @function
 *
 * @param {object} user - The user object.
 * @param {string} user.first_name - The first name of the user.
 * @param {string} user.last_name - The last name of the user.
 * @returns {string} The initials of the user.
 */
export const getUserInitial = (user) => {
  if (!user) return '';
  const { first_name, last_name } = user;
  return `${first_name?.charAt(0)}${last_name?.charAt(0)}`;
};

/**
 * Returns the formatted birth date.
 *
 * @function
 * @param {string} date - The birth date. (ex. '1995-11-03T23:00:00.000Z')
 * @returns {string} The formatted birth date. (ex. '04-11-1995') or '-' if no date is provided.
 */
export const getFormattedDate = (date) => {
  if (!date) return '-';
  return dayjs(date).format(dateFormat);
};

/**
 * Returns the formatted birth date.
 *
 * @function
 * @param {string} date - The birth date. (ex. '1995-11-03T12:00:00.000Z')
 * @returns {string} The formatted birth date. (ex. '04-11-1995 à 12:00') or '-' if no date is provided.
 */
export const getFormattedDateWithTime = (date) => {
  if (!date) return '-';
  return dayjs(date).format(dateFormatWithTime);
};

/**
 * Calculates the age based on the given birth date and returns the age.
 *
 * @function
 * @param {string} birth_date - The birth date. (ex: '1955-06-14T23:00:00.000Z')
 * @returns {number} The age in years. (ex: 68 ans)
 */
export const getAge = (birth_date) => {
  const birthDate = dayjs(birth_date);
  const today = dayjs();

  return today.diff(birthDate, 'years');
};

/**
 * Returns the formatted deal amount.
 * @function
 * @param {number} amount - The deal amount. (ex: 1200000)
 * @returns {string} The formatted deal amount. (ex: '1 200 000,00 €')
 */
export const getFormattedAmount = (amount) => {
  if (amount === 0) return '0,00 €';

  // Convertir le montant en nombre pour gérer les décimales
  let amountNum = parseFloat(amount);

  // Vérifier si le montant est un nombre valide
  if (Number.isNaN(amountNum)) {
    return 'Invalid amount';
  }

  // Arrondir le montant à deux décimales
  amountNum = Math.round(amountNum * 100) / 100;

  // Séparer les parties entière et décimale
  // eslint-disable-next-line prefer-const
  let [integerPart, decimalPart] = amountNum.toFixed(2).split('.');

  // Ajouter des espaces tous les 3 caractères à partir de la fin
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Ajouter un espace avant la partie décimale
  integerPart = integerPart.replace(/(\d)(?=(\d{3})+\b)/g, '$1 ');

  // Ajouter le symbole de l'euro à la fin
  const formattedAmount = `${integerPart},${decimalPart} €`;

  return formattedAmount;
};
