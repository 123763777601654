import { Timeline, Flex, Button, Popconfirm } from 'antd';
import {
  FormOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import {
  getFormattedDate,
  getFormattedDateWithTime,
  getFullName
} from '../../../../utils/formatters';

const { Item } = Timeline;
// Work in progress on this component

/**
 * Hook to get timeline item based on the item type
 * @hook
 * @param {function} t - Translation function
 * @param {string} translationKey - Translation key
 * @param {object} item - Item to use
 * @param {function} handleNoteDrawer - Function to handle note drawer
 * @param {function} handleCallDrawer - Function to handle call drawer
 * @param {function} archiveNote - Function to archive note
 * @returns {object} Timeline item
 */
export const useTimelineItem = (
  t,
  translationKey,
  item,
  handleNoteDrawer,
  handleCallDrawer,
  archiveNote
) => {
  const { _id, case: caseHistory, date, user, new_note, comment } = item;

  const items = {
    DEAL_CREATION: (
      <Item key={_id}>
        <span style={{ color: 'var(--seocndaryColor)' }}>
          {getFormattedDate(date)} • {getFullName(user)}
        </span>
        <p>{t(`${translationKey}.cases.${caseHistory}`)}</p>
      </Item>
    ),
    NEW_NOTE: (
      <Item key={_id} dot={<FormOutlined />}>
        <Flex justify="space-between">
          <Flex vertical>
            <span style={{ color: 'var(--seocndaryColor)' }}>
              {getFormattedDate(date)} • {getFullName(user)}
            </span>
            <p>{new_note}</p>
          </Flex>
          <Flex align="center" gap="small">
            <Button type="link" onClick={() => handleNoteDrawer('edit', item)}>
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => archiveNote(item._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)' }}
                type="delete"
              />
            </Popconfirm>
          </Flex>
        </Flex>
      </Item>
    ),
    NEW_CALL: (
      <Item key={_id} dot={<PhoneOutlined />}>
        <Flex justify="space-between">
          <Flex vertical>
            <span style={{ color: 'var(--secondaryColor)' }}>
              {getFormattedDateWithTime(date)} • {getFullName(user)}
            </span>
            <p>
              {t(`${translationKey}.cases.${caseHistory}`)}
              {comment && ` • ${comment}`}
            </p>
          </Flex>
          <Flex align="center" gap="small">
            <Button type="link" onClick={() => handleCallDrawer('edit', item)}>
              <EditOutlined />
            </Button>
          </Flex>
        </Flex>
      </Item>
    )
  };

  return items;
};
