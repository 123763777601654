import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal, Form, Select, Button, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { usePatchDealStage } from '../patchDealStage';

const { Option } = Select;

/**
 * Modal to change the stage of a deal
 * @component
 *
 * @param {object} props Component props
 * @param {string} props.deal_id ID of the deal to change the stage
 * @param {string} props.new_stage New stage of the deal
 * @param {string} props.previous_stage Previous stage of the deal
 * @param {function} props.setForceRefresh Function to set the forceRefresh state
 * @returns {JSX.Element} Modal to change the stage of a deal
 */
export const ModalChangeStage = ({
  deal_id,
  new_stage,
  previous_stage,
  setForceRefresh
}) => {
  const translationKey = 'deals.form';
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const { patchDealStage } = usePatchDealStage();
  const [open, setOpen] = useState(true);
  const [reasons, setReasons] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onFinish = async (values) => {
    try {
      await patchDealStage(new_stage, previous_stage, values.reason, deal_id);

      setOpen(false);
      setForceRefresh((prevState) => !prevState);
    } catch (error) {
      message(error);
    }
  };

  const getReasonsChangeStage = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/reason-stage-changes?agency=${agency}&target_stages=${new_stage}&archived=false`
      });

      setReasons(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getReasonsChangeStage();
      setIsFieldsLoading(false);
    })();
  }, []);

  return (
    <Modal
      title={t(`${translationKey}.stages.${new_stage}`)}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="reason"
          label={t(`${translationKey}.reason_stage_change`)}
          rules={[{ required: true }]}
        >
          <Select loading={isFieldsLoading}>
            {reasons.map((r) => (
              <Option key={r._id} value={r._id}>
                {r.reason}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Flex justify="end" gap="small">
            <Button type="link" danger onClick={() => setOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalChangeStage.propTypes = {
  deal_id: PropTypes.string.isRequired,
  new_stage: PropTypes.string.isRequired,
  previous_stage: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
