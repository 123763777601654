import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Patches the deal stage
 * @hook
 * @param {string} new_stage - The new stage
 * @param {string} previous_stage - The previous stage
 * @param {string} reason - The reason for the change
 * @param {string} deal_id - The deal id
 * @returns {Promise<void>} - The promise of the patch
 */
export const usePatchDealStage = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const patchDealStage = async (new_stage, previous_stage, reason, deal_id) => {
    const body = {
      deal: deal_id,
      case: 'UPDATE_DEAL_STAGE',
      user: user._id,
      update_deal_stage: {
        new_stage,
        previous_stage,
        reason
      }
    };

    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${deal_id}/update-stage`,
        body
      });
    } catch (error) {
      message(error);
    }
  };

  return { patchDealStage };
};
