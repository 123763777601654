import { Route, Routes } from 'react-router-dom';
import { ListAgencies } from './ListAgencies';
import { CreateUpdateAgency } from './CreateUpdateAgency';
import { ShowAgency } from './ShowAgency';
import { Exception } from '../../components';

/**
 * Renders the router component for the agencies.
 *
 * Available routes:
 * - `/` - The list of agencies.
 * - `/create` - The form to create a new agency.
 * - `/edit/:id` - The form to edit an existing agency.
 * - `/show/:id` - The agency details.
 * - `*` - The exception page.
 * @returns {JSX.Element} The router component.
 */
export const AgencyRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateAgency purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateAgency purpose="edit" />} />
    <Route path="/show/:id" element={<ShowAgency />} />
    <Route index element={<ListAgencies />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
