import { useTranslation } from 'react-i18next';
import { AgencyConfiguration } from './AgencyConfiguration/AgencyConfiguration';
import { ListAppointmentSubjects } from './AppointmentSubjects/ListAppointmentSubjects';
import { ListReasonStageChange } from './ReasonStageChange/ListReasonStageChange';
import { ListDealsOrigin } from './DealsOrigin/ListDealsOrigin';

/**
 * Configuration menu items
 * @returns {Object} Configuration menu items
 */
export const useMenuItems = () => {
  const { t } = useTranslation();
  const translationKey = 'configuration.menu_items';

  const items = [
    {
      type: 'divider'
    },
    {
      label: t(`${translationKey}.agency_configuration`),
      key: 'agency_configuration',
      component: <AgencyConfiguration />
    },
    {
      type: 'divider'
    },
    {
      label: t(`${translationKey}.appointment_subjects`),
      key: 'appointment_subjects',
      component: <ListAppointmentSubjects />
    },
    {
      type: 'divider'
    },
    {
      label: t(`${translationKey}.reasons_stage_change`),
      key: 'reasons_stage_change',
      component: <ListReasonStageChange />
    },
    {
      label: t(`${translationKey}.deals_origin`),
      key: 'deals_origin',
      component: <ListDealsOrigin />
    }
  ];

  return { items };
};
