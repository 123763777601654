import { Route, Routes } from 'react-router-dom';
import { Deals } from './Deals';
import { ShowDeal } from './Show/ShowDeal';
import { Exception } from '../../components';

/**
 * Renders the router component for deals requests.
 *
 * Available routes:
 * - `/show/:id` - The show page.
 * - `/` - The deals page.
 * - `*` - The exception page.
 * @returns {JSX.Element} The router component.
 */
export const DealsRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowDeal />} />
    <Route index element={<Deals />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
