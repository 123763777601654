import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSuccessMessage } from '../../utils/successMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { CustomerForm } from './forms/CustomerForm/CustomerForm';
import { BankInformationsForm } from './forms/BankInformations/BankInformationsForm';
import { ManagementForm } from './forms/ManagementForm/ManagementForm';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Create or update a customer
 * @param {string} purpose - Purpose of the component (create or edit)
 * @returns {JSX.Element} Component to create or update a customer
 */
export const CreateUpdateCustomer = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const [currentStep, setCurrentStep] = useState(0);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [bankInformations, setBankInformations] = useState(null);
  const [managementInformations, setManagementInformations] = useState(null);
  const [sendingForm, setSendingForm] = useState(false);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);
  const translationKey = 'customers.form';

  const handleCustomerDetails = (values) => {
    setCustomerDetails(values);
    setCurrentStep(currentStep + 1);
  };

  const handleBankInformations = (values) => {
    setBankInformations(values);
    setCurrentStep(currentStep + 1);
  };

  const submitForm = async (values) => {
    try {
      setSendingForm(true);
      const body = {
        customer: {
          ...values,
          ...customerDetails
        },
        bank_informations: bankInformations
      };
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', { url: `/customers/${id}`, body });
      } else {
        await dispatchAPI('POST', { url: `/customers`, body });
      }
      successMessage(`customer_${purpose}`);
      navigate(routes.CUSTOMERS);
    } catch (error) {
      message(error);
    } finally {
      setSendingForm(false);
    }
  };

  const getInitialValues = async () => {
    try {
      setLoadingInitialValues(true);
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}?populate=rib`
      });

      setCustomerDetails({
        ...data,
        company_creation_date:
          data.company_creation_date && dayjs(data.company_creation_date)
      });
      setBankInformations(data.rib);
      setManagementInformations({
        agency: data?.agency,
        client_relationship_manager: data?.client_relationship_manager,
        primary_placement_agent: data?.primary_placement_agent,
        secondary_placement_agent: data?.secondary_placement_agent
      });
    } catch (error) {
      message(error);
    } finally {
      setLoadingInitialValues(false);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getInitialValues();
      })();
    }
  }, [id, purpose]);

  const handlePrevious = () => setCurrentStep(currentStep - 1);

  const steps = [
    { title: t(`${translationKey}.steps.customer_details`), key: 1 },
    { title: t(`${translationKey}.steps.bank_informations`), key: 2 },
    { title: t(`${translationKey}.steps.management`), key: 3 }
  ];

  const forms = [
    <CustomerForm
      handleNextStep={handleCustomerDetails}
      initialValues={customerDetails}
      purpose={purpose}
      loading={loadingInitialValues}
    />,
    <BankInformationsForm
      handleNextStep={handleBankInformations}
      handlePrevious={handlePrevious}
      initialValues={bankInformations}
      purpose={purpose}
    />,
    <ManagementForm
      handleNextStep={submitForm}
      handlePrevious={handlePrevious}
      sendingForm={sendingForm}
      initialValues={managementInformations}
      purpose={purpose}
    />
  ];

  return (
    <>
      <PageHeaderCustom
        title={
          purpose === 'create'
            ? t(`${translationKey}.titles.create`)
            : t(`${translationKey}.titles.edit`)
        }
      />
      <ContentCustom>
        <Steps style={{ marginBottom: 16 }} current={currentStep}>
          {steps.map((step) => (
            <Steps.Item key={step.title} title={step.title} icon={step.icon} />
          ))}
        </Steps>
        {forms[currentStep]}
      </ContentCustom>
    </>
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: PropTypes.string.isRequired
};
