import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useFields } from './fields';
import { CreateUpdateContainerWithFileManager } from '../../components';

/**
 * The form for creating or updating an agency
 * @param {string} purpose - Purpose of the form (create or edit)
 * @returns {JSX.Element} CreateUpdateAgency component
 */
export const CreateUpdateAgency = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        company_creation_date:
          data.company_creation_date && dayjs(data.company_creation_date)
      })
    }
  };

  return (
    <CreateUpdateContainerWithFileManager
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="agencies"
      resource="agencies"
      config={config}
    />
  );
};

CreateUpdateAgency.propTypes = {
  purpose: PropTypes.string.isRequired
};
