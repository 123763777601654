// Main nav menuItems
export const routes = {
  HOME: '/',
  // MEETINGS: '/meetings',
  CUSTOMERS: '/customers',
  DEALS: '/deals',
  INSURANCE_COMPANIES: '/insurance_companies',
  // CONTACTS: '/contacts',
  CONFIGURATION: '/configuration'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  CUSTOMERS: '?s=-created_at',
  INSURANCE_COMPANIES: '?s=-created_at',
  CONTACTS: '?s=-created_at'
};
