/* eslint-disable no-console */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Tabs, Timeline, Button, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useSuccessMessage } from '../../../../utils/successMessage';
import { useTimelineItem } from './TimelineItem';
import { NewNoteDrawer } from './Drawers/NewNoteDrawer/NewNoteDrawer';
import { NewCallDrawer } from './Drawers/NewCallDrawer/NewCallDrawer';

// Work in progress on this component
/**
 * DealTimeline
 * @component
 * @param {string} id - ID of the deal
 * @param {string} customerId - ID of the customer
 * @returns {JSX.Element} DealTimeline Component
 */
export const DealTimeline = ({ id, customerId }) => {
  const translationKey = 'case_history';
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [caseHistory, setCaseHistory] = useState([]);
  const [activeKey, setActiveKey] = useState('ALL');
  const [newNoteDrawer, setNewNoteDrawer] = useState(null);
  const [newCallDrawer, setNewCallDrawer] = useState(null);

  const getHistory = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/deal-histories?deal=${id}&populate=user${
          activeKey !== 'ALL' ? `&case=${activeKey}` : ''
        }&sort=-date&archived=false`
      });
      setCaseHistory(data);
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  const archiveNote = async (dealHistoryId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/deal-histories/${dealHistoryId}`
      });
      setForceRefresh((prev) => !prev);
      successMessage('note_deleted');
    } catch (error) {
      message(error);
    }
  };

  const handleNoteDrawer = (purpose, initialValues) => {
    const drawerKey = Date.now();
    setNewNoteDrawer(
      <NewNoteDrawer
        key={drawerKey}
        purpose={purpose}
        dealId={id}
        setForceRefresh={setForceRefresh}
        initialValues={initialValues}
      />
    );
  };

  const handleCallDrawer = (purpose, initialValues) => {
    const drawerKey = Date.now();
    setNewCallDrawer(
      <NewCallDrawer
        key={drawerKey}
        purpose={purpose}
        dealId={id}
        customerId={customerId}
        setForceRefresh={setForceRefresh}
        initialValues={initialValues}
      />
    );
  };

  const items = ['ALL', 'NEW_NOTE', 'NEW_CALL', 'NEW_APPOINTMENT'].map(
    (item) => {
      let children;

      if (isLoading) {
        children = <Timeline pending={isLoading} />;
      } else if (caseHistory.length) {
        children = (
          <Timeline>
            {caseHistory.map(
              (elem) =>
                useTimelineItem(
                  t,
                  translationKey,
                  elem,
                  handleNoteDrawer,
                  handleCallDrawer,
                  archiveNote
                )[elem.case]
            )}
          </Timeline>
        );
      } else {
        children = <Empty />;
      }

      return {
        key: item,
        label: t(`${translationKey}.tabs.${item}`),
        children
      };
    }
  );

  const extraActions = [
    {
      key: 'new_note',
      label: t('buttons.new_note'),
      icon: <PlusOutlined />,
      action: () => handleNoteDrawer('create')
    },
    {
      key: 'new_call',
      label: t('buttons.new_call'),
      icon: <PlusOutlined />,
      action: () => handleCallDrawer('create')
    },
    {
      key: 'new_appointment',
      label: t('buttons.new_appointment'),
      icon: <PlusOutlined />,
      action: (key) => console.log(key)
    }
  ];

  useEffect(() => {
    (async () => {
      await getHistory();
    })();
  }, [id, activeKey, forceRefresh]);

  return (
    <>
      {newNoteDrawer}
      {newCallDrawer}
      <Tabs
        defaultActiveKey="ALL"
        items={items}
        onChange={(key) => setActiveKey(key)}
        className="deal-tabs"
        tabBarExtraContent={
          <Flex gap="small">
            {extraActions.map((item) => (
              <Button key={item.key} onClick={() => item.action(item.key)}>
                {item.icon}
                {item.label}
              </Button>
            ))}
          </Flex>
        }
      />
    </>
  );
};

DealTimeline.propTypes = {
  id: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired
};
