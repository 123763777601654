import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

/**
 * List of insurance companies
 * @component
 * @returns {JSX.Element} The list of insurance companies
 */
export const ListInsuranceCompanies = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="insurancecompanies"
      tradKey="insurance_companies"
      resourceModelName="Insurancecompany"
      columns={columns}
      withImportButton={false}
      headers={headers}
    />
  );
};
