import { Route, Routes, useParams } from 'react-router-dom';
import { InsuranceCompanyContextProvider } from './InsuranceCompanyContext';
import { ListInsuranceCompanies } from './ListInsuranceCompanies';
import { CreateUpdateInsuranceCompany } from './CreateUpdateInsuranceCompany';
import { ShowInsuranceCompany } from './ShowInsuranceCompany';
import { Exception } from '../../components';

/**
 * Wrapper to provide the insurance context with idCompany.
 * It retrieves the ID from the URL parameters and passes it as a prop.
 *
 * This is used inside each route where the `id` is available in the URL.
 *
 * @returns {JSX.Element} The InsuranceCompanyContextProvider wrapping the children.
 */
const InsuranceCompanyContextWrapper = ({ children }) => {
  const { id } = useParams(); // Retrieves the ID from the URL parameters
  return (
    <InsuranceCompanyContextProvider idCompany={id}>
      {children}
    </InsuranceCompanyContextProvider>
  );
};

/**
 * Renders the router component for insurance companies requests.
 *
 * Available routes:
 * - `/` - The list of insurance companies.
 * - `/create` - The form to create a new insurance company.
 * - `/edit/:id` - The form to edit an existing insurance company.
 * - `/show/:id` - The page to show company information.
 * - `*` - The exception page.
 * @returns {JSX.Element} The router component.
 */
export const InsuranceCompanyRouter = () => (
  <Routes>
    <Route
      index
      element={
        <InsuranceCompanyContextWrapper>
          <ListInsuranceCompanies />
        </InsuranceCompanyContextWrapper>
      }
    />
    <Route
      path="/create"
      element={
        <InsuranceCompanyContextWrapper>
          <CreateUpdateInsuranceCompany purpose="create" />
        </InsuranceCompanyContextWrapper>
      }
    />
    <Route
      path="/edit/:id"
      element={
        <InsuranceCompanyContextWrapper>
          <CreateUpdateInsuranceCompany purpose="edit" />
        </InsuranceCompanyContextWrapper>
      }
    />
    <Route
      path="/show/:id"
      element={
        <InsuranceCompanyContextWrapper>
          <ShowInsuranceCompany />
        </InsuranceCompanyContextWrapper>
      }
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
