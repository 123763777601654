import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Button, Popconfirm, Flex, Col, Row } from 'antd';
import {
  EditOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { CheckmarkOutline, Star, StarFilled } from '@carbon/icons-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useSuccessMessage } from '../../../utils/successMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { useListContent } from './listContent';
import { CreateUpdateDealDrawer } from '../CreateUpdateDeal/CreateUpdateDealDrawer';
import { ModalDealWin } from '../Modals/ModalDealWin';
import { DealStepper } from './DealStepper';
import { DealTimeline } from './DealTimeline/DealTimeline';
import { ListConsultations } from './Consultations/ListConsultations';
import { ListContacts } from '../../customers/show/contacts/ListContacts';
import FilesManagerShow from '../../../components/CreateUpdateContainer/FilesManager/FilesManagerShow';

// Work in progress on this component
/**
 * Show Deal
 * @component
 * @returns {JSX.Element} Show Deal Component
 */
export const ShowDeal = () => {
  const translationKey = 'deals.show';
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [createUpdateDealDrawer, setCreateUpdateDealDrawer] = useState(null);
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [deal, setDeal] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [fileLists, setFileLists] = useState({});
  const listContent = useListContent(deal);

  const getDeal = useCallback(async () => {
    try {
      setIsLoading(true);

      const population = [
        'customer',
        'principal_contact',
        'deal_manager',
        'deal_origin',
        'decision_maker',
        'dda_files',
        'other_files'
      ];

      const { data } = await dispatchAPI('GET', {
        url: `/deals/${id}?populate=${population.join(',')}`
      });
      setIsFavorite(data.favorites?.includes(user?._id));
      setDeal(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const archiveDeal = async (_id) => {
    try {
      await dispatchAPI('DELETE', { url: `/deals/${_id}` });

      successMessage('deal_archived');
      setForceRefresh((prev) => !prev);
    } catch (error) {
      message(error);
    }
  };

  const handleFavorites = async (method) => {
    try {
      const formData = new FormData();
      formData.append(
        'values',
        JSON.stringify({ [method]: { favorites: user?._id } })
      );

      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: formData
      });

      setIsFavorite(!isFavorite);
      successMessage(
        method === '$push'
          ? 'deal_added_to_favorites'
          : 'deal_removed_from_favorites'
      );
    } catch (error) {
      message(error);
    }
  };

  const handleEditDeal = () => {
    const drawerKey = Date.now();
    setCreateUpdateDealDrawer(
      <CreateUpdateDealDrawer
        key={drawerKey}
        purpose="edit"
        initialValues={deal}
        setForceRefresh={setForceRefresh}
      />
    );
  };

  const handleDealWin = () => {
    const modalKey = Date.now();
    setModal(
      <ModalDealWin
        key={modalKey}
        deal_id={id}
        new_stage="DEAL_WON"
        previous_stage={deal?.stage}
        setForceRefresh={setForceRefresh}
      />
    );
  };

  useEffect(() => {
    (async () => {
      await getDeal();
    })();
  }, [getDeal, forceRefresh]);

  return isLoading ? (
    <CustomSpin fullPage />
  ) : (
    <>
      <PageHeaderCustom
        title={deal?.name}
        extra={
          <>
            <Button
              onClick={() => handleFavorites(isFavorite ? '$pull' : '$push')}
            >
              {isFavorite ? <StarFilled /> : <Star />}
              {isFavorite
                ? t('buttons.remove_from_favorites')
                : t('buttons.add_to_favorites')}
            </Button>
            {deal?.stage !== 'DEAL_WON' && (
              <Button type="primary" onClick={() => handleDealWin()}>
                <CheckmarkOutline />
                {t('buttons.deal_won')}
              </Button>
            )}
            <Button onClick={() => handleEditDeal()}>
              <EditOutlined />
              {t('buttons.edit')}
            </Button>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => archiveDeal(id)}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                <ContainerOutlined />
                {t('buttons.archive')}
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        {createUpdateDealDrawer}
        {modal}
        <DealStepper
          currentStage={deal?.stage}
          setForceRefresh={setForceRefresh}
          dealId={id}
        />
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Flex vertical gap="middle">
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={14}>
                <Flex vertical gap="middle">
                  <Card
                    title={t(`${translationKey}.titles.principal_informations`)}
                  >
                    <DescriptionList
                      data={listContent}
                      layout="vertical"
                      column={4}
                      translate
                    />
                  </Card>
                  <FilesManagerShow
                    resourceName="deals"
                    tradKey="deals"
                    fileKeys={['dda_files', 'other_files']}
                    fileLists={fileLists}
                    setFileLists={setFileLists}
                    resource={deal}
                    getResource={getDeal}
                  />
                </Flex>
              </Col>
              <Col xs={24} xl={10}>
                <DealTimeline id={id} customerId={deal?.customer?._id} />
              </Col>
            </Row>
            <Card title={t(`${translationKey}.titles.consultations`)}>
              <ListConsultations deal={deal} />
            </Card>
            <ListContacts
              customerId={deal?.customer?._id}
              customer={deal?.customer}
            />
          </Flex>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
