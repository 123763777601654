import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CreateUpdateContainerWithFileManager } from '../../../components';
import { useFields } from './fieldsDealWin';

/**
 * Modal to deal win
 * @component
 * @param {string} deal_id - ID of the deal
 * @param {string} new_stage - New stage of the deal
 * @param {string} previous_stage - Previous stage of the deal
 * @param {function} setForceRefresh - Function to set the force refresh state
 * @returns {JSX.Element} Modal to deal win
 */
export const ModalDealWin = ({
  deal_id,
  new_stage,
  previous_stage,
  setForceRefresh
}) => {
  const translationKey = 'deals.form';
  const { t } = useTranslation();
  const { fields } = useFields(deal_id);
  const [open, setOpen] = useState(true);

  const config = {
    onUpdateResource: {
      setBody: (data) => {
        const body = {
          ...data,
          new_stage,
          previous_stage
        };
        return body;
      }
    }
  };

  const customFinalAction = () => {
    setForceRefresh((prevState) => !prevState);
    setOpen(false);
  };

  return (
    <Modal
      title={t(`${translationKey}.deal_win`)}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <CreateUpdateContainerWithFileManager
        config={config}
        fields={fields}
        resourceId={deal_id}
        customFinalAction={customFinalAction}
        customCancel={() => setOpen(false)}
        customSubmitUrl={`/deals/${deal_id}/deal-win`}
        purpose="edit"
        baseUrl="deals"
        resource="deals"
        withPageHeader={false}
        formDirection="vertical"
        contentCustomStyle={{
          padding: 0,
          background: 'var(--componentBackground)'
        }}
      />
    </Modal>
  );
};

ModalDealWin.propTypes = {
  deal_id: PropTypes.string.isRequired,
  new_stage: PropTypes.string.isRequired,
  previous_stage: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
