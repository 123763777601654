import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import { Flex } from 'antd';
import { DealCard } from './DealCard';

/**
 * KanbanColumn
 * @component
 * @param {function} handleEditDeal - function to handle edit deal
 * @param {function} archiveDeal - function to archive deal
 * @param {object} column - column object
 * @param {function} setForceRefresh - function to set force refresh
 * @returns {JSX.Element} KanbanColumn component
 */
export const KanbanColumn = ({
  handleEditDeal,
  archiveDeal,
  column,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const translationKey = 'deals.form';
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'DEAL',
    drop: () => ({ target: column }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = canDrop && isOver;

  return (
    <Flex
      vertical
      ref={drop}
      style={{
        background: isActive && 'rgba(255,255,255,0.5)'
      }}
      className="kanban-column"
    >
      <Flex className="kanban-col-title">
        <div id="label">{t(`${translationKey}.stages.${column?.stage}`)}</div>
        <div id="label">{column?.percentage}</div>
      </Flex>
      <Flex vertical className="kanban-card-container">
        {column?.deals.map((deal) => (
          <DealCard
            key={deal._id}
            handleEditDeal={handleEditDeal}
            archiveDeal={archiveDeal}
            deal={deal}
            currentStage={column?.stage}
            setForceRefresh={setForceRefresh}
          />
        ))}
      </Flex>
    </Flex>
  );
};

KanbanColumn.propTypes = {
  archiveDeal: PropTypes.func.isRequired,
  handleEditDeal: PropTypes.func.isRequired,
  column: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
