import { Link } from 'react-router-dom';
import { Button, Card, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ClockCircleOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { routes } from '../../utils/constants/userRoutes';
import useInsuranceCompanyContext from './InsuranceCompanyContext';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import { ListContacts } from './ListContacts';
import ContactDrawer from './ContactDrawer';
import FilesManagerShow from '../../components/CreateUpdateContainer/FilesManager/FilesManagerShow';

export const ShowInsuranceCompany = () => {
  const { t } = useTranslation();
  const {
    company,
    isLoading,
    isDrawerOpen,
    idCompany,
    fileLists,
    setFileLists,
    getCompany
  } = useInsuranceCompanyContext();
  const listContent = useListContent(company);

  return isLoading ? (
    <CustomSpin fullPage />
  ) : (
    <>
      <PageHeaderCustom
        title={`${company.number} - ${company.abbreviated_name}`}
        extra={
          <>
            <Button type="primary">
              <ClockCircleOutlined />
              {t('buttons.historic')}
            </Button>
            <Link to={`${routes.INSURANCE_COMPANIES}/edit/${company._id}`}>
              <Button type="primary">
                <EditOutlined />
                {t('buttons.edit')}
              </Button>
            </Link>
            <Button danger type="link">
              <ContainerOutlined />
              {t('buttons.archive')}
            </Button>
          </>
        }
      />
      <ContentCustom>
        {isDrawerOpen && <ContactDrawer idCompany={idCompany} />}
        <Flex vertical gap="middle">
          <Card>
            <DescriptionList data={listContent} translate />
          </Card>
          <ListContacts />
          <FilesManagerShow
            resourceName="insurancecompany"
            tradKey="insurance_companies"
            fileKeys={['rib_files', 'other_files']}
            fileLists={fileLists}
            setFileLists={setFileLists}
            resource={company}
            getResource={getCompany}
          />
        </Flex>
      </ContentCustom>
    </>
  );
};
