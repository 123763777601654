import { useTranslation } from 'react-i18next';
import { CheckmarkFilled, CloseOutline } from '@carbon/icons-react';
import { MailOutlined } from '@ant-design/icons';

/**
 * Hook to get columns for the company contacts table
 * @hook
 * @returns {Array} Columns for the conpany contacts table
 */
export const useColumns = () => {
  const translationKey = 'contacts.form';
  const { t } = useTranslation();

  return [
    {
      title: t(`${translationKey}.first_name`),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t(`${translationKey}.last_name`),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t(`${translationKey}.is_principal_contact`),
      key: 'is_principal_contact',
      dataIndex: 'is_principal_contact',
      width: 160,
      render: (is_principal_contact) =>
        is_principal_contact ? (
          <CheckmarkFilled color="var(--successColor)" size={18} />
        ) : (
          <CloseOutline color="var(--errorColor)" size={18} />
        ),
      sorter: true
    },
    {
      title: t(`${translationKey}.email`),
      key: 'email',
      dataIndex: 'email',
      render: (email) =>
        email ? (
          <>
            <MailOutlined style={{ marginRight: 10 }} />
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </>
        ) : (
          '-'
        ),
      sorter: true
    },
    {
      title: t(`${translationKey}.phone_numbers`),
      key: 'phone_numbers',
      dataIndex: 'phone_numbers',
      render: (phone_numbers) =>
        phone_numbers.map((phone) => phone.number).join(' - ')
    }
  ];
};
