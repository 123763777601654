import { createGlobalStyle } from 'styled-components';

export const LightTheme = createGlobalStyle`
  body {
    color-scheme: light;
    --clientColor: #fff;
    --primaryColor: #13277c;
    --addColor: #47d267;
    --componentBackground: #fff;
    --secondaryColor: #617EFF;
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --primaryHover: var(--secondaryColor);
    --secondaryHover: #ebeeff;
    --textColor: #212529;
    --textColorSecondary: #5f6973;
    --textColorActive: var(--secondaryColor);
    --textColorInvert: #fff;
    --itemActiveBackground: var(--secondaryHover);
    --itemHoverBackground: var(--secondaryHover);
    --borderColor: #d9d9d9;
    --borderColorSecondary: #e6e6e6;
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --boxShadowColor: var(--borderColor);
    --boxShadow: 2px 2px 4px var(--borderColor);
    --borderRadius: 4px;
    --borderRadiusXS: 2px;
    --borderRadiusLG: 8px;
  }
`;

export default LightTheme;
