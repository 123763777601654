import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from './contactFields';
import useInsuranceCompanyContext from './InsuranceCompanyContext';

const ContactDrawer = () => {
  const { t } = useTranslation();
  const { purpose, isDrawerOpen, closeDrawer, idFromOverlay, isLoading } =
    useInsuranceCompanyContext();
  const { fields } = useFields();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...data }));
        return formData;
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...data }));
        return formData;
      }
    }
  };

  return (
    <Drawer
      centered
      title={t(`contacts.form.title.${purpose}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isLoading}
        purpose={purpose}
        baseUrl="contacts"
        resource="contacts"
        isOverlay
        customNavigate={closeDrawer}
        withHeader={false}
        idFromOverlay={idFromOverlay}
        config={config}
        layout="vertical"
        submitLabel={t('buttons.register')}
      />
    </Drawer>
  );
};

export default ContactDrawer;
