import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { getFullName } from '../../../../utils/formatters';

const { Option } = Select;

/**
 * Hook to get management fields
 * @hook
 * @param {Object} initialValues - initial values
 * @returns {Object} management fields
 */
export const useFields = (initialValues) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [selectedAgency, setSelectedAgency] = useState(
    initialValues?.agency || null
  );
  const [agencies, setAgencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const getAgencies = async () => {
    try {
      setLoadingAgencies(true);
      const { data } = await dispatchAPI('GET', {
        url: `/agencies?archived=false`
      });
      setAgencies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setLoadingAgencies(false);
    }
  };

  const getAgencyUsers = async () => {
    try {
      setLoadingUsers(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?agency=${selectedAgency}&archived=false`
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(async () => {
    if (selectedAgency) {
      await getAgencyUsers();
    }
  }, [selectedAgency]);

  useEffect(() => {
    (async () => {
      await getAgencies();
    })();
  }, []);

  const fields = [
    {
      name: ['agency'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={loadingAgencies}
          onChange={(e) => setSelectedAgency(e)}
        >
          {agencies.map((agency) => (
            <Option key={agency._id} value={agency._id}>
              {agency.abbreviated_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['client_relationship_manager'],
      rules: [{ required: true }],
      input: (
        <Select loading={loadingUsers} disabled={!selectedAgency}>
          {users.map((user) => (
            <Option key={user._id} value={user._id}>
              {getFullName(user)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['primary_placement_agent'],
      input: (
        <Select loading={loadingUsers} disabled={!selectedAgency}>
          {users.map((user) => (
            <Option key={user._id} value={user._id}>
              {getFullName(user)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['secondary_placement_agent'],
      input: (
        <Select loading={loadingUsers} disabled={!selectedAgency}>
          {users.map((user) => (
            <Option key={user._id} value={user._id}>
              {getFullName(user)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields
  };
};
