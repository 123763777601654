import { useState, useEffect } from 'react';
import { Select, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

/**
 * @description AgencySelector component
 * @component
 * @returns {JSX} The agencies selector for the admin and for users that are on multiple agencies
 */
const AgencySelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser, agency, setAgency, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [agencies, setAgencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const patchSelectedAgency = async (agencyId) => {
    if (agencyId === 'ADMINISTRATION') {
      setUser({ ...user, last_selected_agency: agencyId });
      return;
    }

    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/users/${user._id}/change-agency`,
        body: {
          last_selected_agency: agencyId
        }
      });
      setUser(data);
    } catch (error) {
      message(error);
    }
  };

  const selectAgency = (value) => {
    setIsLoading(true);
    setAgency(value);
    patchSelectedAgency(value);
    navigate('/');
    setIsLoading(false);
  };

  const getAgencies = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/agencies?fields=-logo,-signature'
      });
      setAgencies(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getAgencies();
    })();
  }, []);

  const arrayData = () => {
    const agencyCells = agencies
      .map((item) => ({
        value: item._id,
        label: item.abbreviated_name
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [
      ...[
        {
          value:
            user.role === 'admins:SUPER-ADMIN' ? 'ADMINISTRATION' : undefined,
          label:
            user.role === 'admins:SUPER-ADMIN'
              ? 'Administration'
              : t('placeholder.select_agency')
        }
      ],
      ...agencyCells
    ];
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div
      style={{
        width: 'calc(100% - 32px)',
        margin: 16
      }}
    >
      <Divider style={{ backgroundColor: 'var(--clientColor)' }} />
      <Select
        variant="borderless"
        loading={isLoading}
        value={user.last_selected_agency || agency || undefined}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={(value) => selectAgency(value)}
        options={arrayData()}
        dropdownStyle={{ minWidth: 200 }}
        style={{
          width: '100%'
        }}
      />
    </div>
  );
};
export default AgencySelector;
