import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Flex } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import { useFields } from './fields';

/**
 * Management form
 * @component
 * @param {Function } handleNextStep - Callback function to handle next step
 * @param {Function } handlePrevious - Callback function to handle previous step
 * @param {Boolean } sendingForm - Flag to indicate if form is sending
 * @param {Object } initialValues - Initial values of form
 * @param {String } purpose - Purpose of form
 * @returns {JSX.Element} Management form
 */
export const ManagementForm = ({
  handleNextStep,
  handlePrevious,
  sendingForm,
  initialValues,
  purpose
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { fields } = useFields(initialValues);
  const generateFields = useGenerateFormItem();

  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, purpose]);

  return (
    <Form onFinish={handleNextStep} {...formItemLayout} form={form}>
      {fields.map((field) => generateFields('customers', field))}
      <Flex justify="center" gap="small">
        <Button type="link" onClick={handlePrevious} disabled={sendingForm}>
          {t('buttons.previous')}
        </Button>
        <Button type="primary" htmlType="submit" loading={sendingForm}>
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
      </Flex>
    </Form>
  );
};

ManagementForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  sendingForm: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  purpose: PropTypes.string.isRequired
};

ManagementForm.defaultProps = {
  initialValues: {}
};
