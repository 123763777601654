import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainerWithFileManager } from '../../components/CreateUpdateContainer/CreateUpdateContainerWithFileManager';

/**
 * Create or update an insurance company
 * @component
 * @param {string} purpose - Purpose of the component (create or edit)
 * @returns {JSX.Element} Component to create or update an insurance company
 */
export const CreateUpdateInsuranceCompany = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainerWithFileManager
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="insurancecompany"
      resource="insurance_companies"
    />
  );
};

CreateUpdateInsuranceCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};
