import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * ListCustomers component.
 *
 * @returns {JSX.Element} The list of customers.
 */
export const ListCustomers = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="customers"
      resourceModelName="Customer"
      populate="client_relationship_manager,primary_placement_agent,secondary_placement_agent"
      columns={columns}
      withUploadButton={false}
      scroll={{ x: 2000 }}
    />
  );
};
