import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * List agencies
 * @returns {JSX.Element} A JSX element rendering the agencies list.
 */
export const ListAgencies = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="agencies"
      columns={columns}
      resourceModelName="Agency"
    />
  );
};
