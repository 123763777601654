import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { CrownOutlined, WarningOutlined } from '@ant-design/icons';
import { getPhoneNumber, getFullName } from '../../utils/formatters';

/**
 * Columns for the customers table
 * @returns {Array} Columns for the customers table
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'customers.form';

  return [
    {
      title: t(`${translationKey}.number`),
      key: 'number',
      dataIndex: 'number',
      width: 80
    },
    {
      title: t(`${translationKey}.vip`),
      key: 'vip',
      dataIndex: 'vip',
      width: 80,
      render: (vip) =>
        vip ? (
          <Tag color="gold">
            <CrownOutlined /> VIP
          </Tag>
        ) : (
          ''
        )
    },
    {
      title: t(`${translationKey}.red_list`),
      key: 'red_list',
      dataIndex: 'red_list',
      width: 120,
      render: (red_list) =>
        red_list ? (
          <Tag color="volcano">
            <WarningOutlined /> {t(`${translationKey}.red_list`)}
          </Tag>
        ) : (
          ''
        )
    },
    {
      title: t(`${translationKey}.name_and_legal_form`),
      key: 'name_and_legal_form',
      render: (record) =>
        record.name
          ? `${record.name} ${t(
              `${translationKey}.legal_forms.${record.legal_form}`
            )}`
          : '-'
    },
    {
      title: t(`${translationKey}.first_name_and_last_name`),
      key: 'first_name_and_last_name',
      render: (record) =>
        record.first_name && record.last_name ? getFullName(record) : '-'
    },
    {
      title: t(`${translationKey}.prospect/assured`),
      key: 'prospect',
      dataIndex: 'prospect',
      width: 150,
      render: (prospect) => (
        <Tag>
          {prospect
            ? t(`${translationKey}.prospect`)
            : t(`${translationKey}.assured`)}
        </Tag>
      )
    },
    {
      title: t(`${translationKey}.primary_placement_agent`),
      key: 'primary_placement_agent',
      dataIndex: 'primary_placement_agent',
      render: (primary_placement_agent) => getFullName(primary_placement_agent)
    },
    {
      title: t(`${translationKey}.client_relationship_manager`),
      key: 'client_relationship_manager',
      dataIndex: 'client_relationship_manager',
      render: (client_relationship_manager) =>
        getFullName(client_relationship_manager)
    },
    {
      title: t(`${translationKey}.principal_contact`),
      key: 'principal_contact',
      dataIndex: 'principal_contact',
      render: (principal_contact) => getFullName(principal_contact)
    },
    {
      title: t(`${translationKey}.principal_contact_email`),
      key: 'principal_contact_email',
      render: (record) => record?.principal_contact?.email || '-'
    },
    {
      title: t(`${translationKey}.principal_contact_phone_number`),
      key: 'principal_contact_phone_number',
      render: (record) =>
        getPhoneNumber(record?.principal_contact?.phone_number)
    }
  ];
};
