import { useTranslation } from 'react-i18next';
import { Card, Typography, Row, Col, DatePicker } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';

const { MonthPicker } = DatePicker;

// Work in progress
export const Home = () => {
  const { t } = useTranslation();

  const todos = [
    'Opportunités',
    'Opportunités en cours',
    'Montant total des opportunités',
    'Pipeline commerciale (montant)',
    'Prochains RDV découverte',
    'Historique des actions liées aux opportunités'
  ];

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom>
        <MonthPicker
          placeholder="Choisir un mois"
          format="MM-YYYY"
          style={{ marginBottom: 30, width: 300 }}
        />
        <Row gutter={[32, 32]}>
          {todos?.map((todo) => (
            <Col span={8}>
              <Card style={{ height: 400 }}>
                <Typography.Title level={4}>{todo}</Typography.Title>
              </Card>
            </Col>
          ))}
        </Row>
      </ContentCustom>
    </>
  );
};
