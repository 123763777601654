import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * List users
 * @component
 * @returns {JSX.Element} The list of users
 */
export const ListUsers = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      resourceModelName="User"
    />
  );
};
