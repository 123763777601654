import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Popconfirm } from 'antd';
import { ContainerOutlined, WarningOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ModalAddDealOrigin } from './ModalAddDealOrigin';

/**
 * List of deals origins
 * @returns {JSX.Element} List of deals origins
 */
export const ListDealsOrigin = () => {
  const baseUrl = '/deal-origins';
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, agency } = useAuthContext();
  const [dealsOrigin, setDealsOrigin] = useState([]);
  const [modal, setModal] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const archiveResource = async (id) => {
    try {
      setLoading(true);
      await dispatchAPI('DELETE', { url: `${baseUrl}/${id}` });
      setForceRefresh((prevState) => !prevState);
    } catch (e) {
      message(e);
    } finally {
      setLoading(false);
    }
  };

  const handleModal = (id) => {
    const modalKey = Date.now();
    setModal(
      <ModalAddDealOrigin
        key={modalKey}
        setForceRefresh={setForceRefresh}
        mainOriginId={id}
      />
    );
  };

  const getDealsOrigin = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `${baseUrl}/${agency}?is_main=true&archived=false&populate=sub_origins`
      });
      setDealsOrigin(data);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getDealsOrigin();
    })();
  }, [forceRefresh]);

  const expandedRowRender = (record) => {
    const columns = [
      {
        dataIndex: 'label',
        key: 'label'
      },
      {
        key: 'action',
        render: (item) => (
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger', loading }}
            cancelButtonProps={{ disabled: loading }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() => archiveResource(item._id)}
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        )
      }
    ];

    return (
      <>
        <Table
          dataSource={record?.children}
          columns={columns}
          loading={loading}
          pagination={false}
          rowKey="label"
          scroll={{ x: 600 }}
        />
        <Button
          style={{ width: '100%' }}
          type="dashed"
          onClick={() => handleModal(record._id)}
        >
          {t('buttons.add_line')}
        </Button>
      </>
    );
  };

  const columns = [
    {
      title: t('deals_origin.form.origin'),
      dataIndex: 'label',
      key: 'label'
    }
  ];

  return (
    <>
      {modal}
      <Table
        dataSource={dealsOrigin}
        columns={columns}
        pagination={false}
        loading={!dealsOrigin.length}
        rowKey="label"
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
          rowExpandable: (record) => record.expandable
        }}
        scroll={{ x: 600 }}
      />
    </>
  );
};
