import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * The DescriptionList component is a wrapper around the Ant Design Descriptions component.
 *
 * @component
 * @example
 * // Example usage:
 * // import { DescriptionList } from './DescriptionList';
 * // ...
 * // <DescriptionList title="Information" data={data} />
 *
 * @param {string} title - The title of the DescriptionList.
 * @param {Array} data - An array of objects representing the data to be displayed in the DescriptionList.
 * @param {boolean} skipEmpty - A flag to skip empty items in the DescriptionList.
 * @param {boolean} translate - A flag to translate the labels in the DescriptionList.
 * @param {string} layout - The layout of the DescriptionList.
 * @param {number} column - The number of columns to display in the DescriptionList.
 * @returns {JSX.Element} The rendered DescriptionList component.
 */
export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  layout,
  column
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title} layout={layout} colon={false} column={column}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 700 }}>
                  {item.content || '-'}
                </Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  layout: PropTypes.string,
  column: PropTypes.number
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  layout: 'horizontal',
  column: 3
};
