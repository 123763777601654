import { useCallback, useEffect, useState } from 'react';
import { Checkbox, Input, Select, TreeSelect, DatePicker, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  getCustomerName,
  getFullName,
  dateFormat
} from '../../../utils/formatters';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Deals form fields
 * @hook
 * @param {string} purpose - purpose of the form
 * @param {object} initialValues - initial values of the form
 * @returns {object} fields
 */
export const useFields = (purpose, initialValues) => {
  const translationKey = 'deals.form';
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [isContactsLoading, setIsContactsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [dealsOrigin, setDealsOrigin] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    initialValues?.customer || null
  );
  const [users, setUsers] = useState([]);

  const handleSelectCustomer = (value) => {
    const customer = customers.find((c) => c._id === value);
    setSelectedCustomer(customer);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDealsOrigin = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deal-origins/${agency}/tree_select?is_main=true&archived=false&populate=sub_origins`
      });

      setDealsOrigin(data);
    } catch (error) {
      message(error);
    }
  };

  const getAgencyUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?agency=${agency}&archived=false`
      });
      setUsers(data);
    } catch (error) {
      message(error);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers?agency=${agency}&archived=false`
      });
      setCustomers(data);
    } catch (error) {
      message(error);
    }
  };

  const getCustomerContacts = async (customerId) => {
    try {
      setIsContactsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/contacts?customer=${customerId}`
      });
      setContacts(data);
    } catch (error) {
      message(error);
    } finally {
      setIsContactsLoading(false);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await Promise.all([
      getEnums(),
      getDealsOrigin(),
      getAgencyUsers(),
      getCustomers()
    ]);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.type === 'ENTITY') {
      (async () => {
        await getCustomerContacts(selectedCustomer?._id);
      })();
    }
  }, [selectedCustomer]);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['customer'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled={purpose === 'edit'}
          loading={isFieldsLoading}
          onChange={(value) => handleSelectCustomer(value)}
        >
          {(customers || []).map((customer) => (
            <Option key={customer._id} value={customer._id}>
              {getCustomerName(customer)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['stage'],
      rules: [{ required: true }],
      input: (
        <Select disabled={purpose === 'edit'} loading={isFieldsLoading}>
          {(enums?.stages || []).map((stage) => (
            <Option key={stage} value={stage}>
              <Tag>{t(`${translationKey}.stages.${stage}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['deal_manager'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(users || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {getFullName(user)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['internal_deadline'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['customer_deadline'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['amount'],
      input: <Input type="number" suffix="€" />
    },
    {
      name: ['replacement_order'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['deal_origin'],
      rules: [{ required: true }],
      input: (
        <TreeSelect
          style={{
            width: '100%'
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto'
          }}
          treeData={dealsOrigin}
        />
      )
    },
    {
      name: ['description'],
      input: <TextArea style={{ resize: 'none' }} rows={5} />
    },
    ...(selectedCustomer?.type === 'ENTITY'
      ? [
          {
            name: ['principal_contact'],
            rules: [{ required: true }],
            input: (
              <Select loading={isContactsLoading} disabled={!selectedCustomer}>
                {(contacts || []).map((contact) => (
                  <Option key={contact._id} value={contact._id}>
                    {getFullName(contact)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['decision_maker'],
            rules: [{ required: true }],
            input: (
              <Select loading={isContactsLoading} disabled={!selectedCustomer}>
                {(contacts || []).map((contact) => (
                  <Option key={contact._id} value={contact._id}>
                    {getFullName(contact)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};
