import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Drawer, Form, Button, Flex } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { useSuccessMessage } from '../../../../../../utils/successMessage';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * NewNoteDrawer
 * @component
 * @param {string} dealId - ID of the deal
 * @param {string} customerId - ID of the customer
 * @param {function} setForceRefresh - Function to set force refresh
 * @param {string} purpose - Purpose of the drawer
 * @param {object} initialValues - Initial values of the drawer
 * @returns {JSX.Element} NewNoteDrawer Component
 */
export const NewCallDrawer = ({
  dealId,
  customerId,
  setForceRefresh,
  purpose,
  initialValues
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const [isLocallyOpen, setIsLocallyOpen] = useState(true);
  const { fields } = useFields(customerId);
  const generateFields = useGenerateFormItem();

  const onFinish = async (body) => {
    try {
      const method = purpose === 'create' ? 'POST' : 'PATCH';
      const url =
        purpose === 'create'
          ? `/deals/${dealId}/call`
          : `/deal-histories/${initialValues?._id}/call`;

      await dispatchAPI(method, {
        url,
        body
      });

      setForceRefresh((prev) => !prev);
      successMessage(purpose === 'create' ? 'call_added' : 'call_updated');
      setIsLocallyOpen(false);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue({
        ...initialValues,
        date: dayjs(initialValues.date)
      });
    }
  }, [initialValues, purpose]);

  return (
    <Drawer
      title={
        purpose === 'create'
          ? t('deals.form.add_new_call')
          : t('deals.form.update_call')
      }
      placement="right"
      onClose={() => setIsLocallyOpen(false)}
      open={isLocallyOpen}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {fields.map((field) => generateFields('deals', field))}
        <Form.Item>
          <Flex justify="end">
            <Button type="link" onClick={() => setIsLocallyOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

NewCallDrawer.propTypes = {
  dealId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string
  })
};

NewCallDrawer.defaultProps = {
  initialValues: null
};
