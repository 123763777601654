import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const InsuranceCompanyContext = createContext({});

/**
 * Context provider component for managing insurance-compny data.
 * This context provides various state values and methods related to insurance-company.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @param {string} props.idCompany - The ID of the insurance company (passed from router)
 * @returns {React.ReactNode} Wrapped children with the contract context
 */
export const InsuranceCompanyContextProvider = ({ children, idCompany }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [enums, setEnums] = useState({});
  const [purpose, setPurpose] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [contactEnums, setContactEnums] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [totalResources, setTotalResources] = useState(0);
  const [fileLists, setFileLists] = useState({});

  const getCompany = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/insurancecompany/${idCompany}?populate=rib_files,other_files`
      });
      setCompany(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/insurancecompany/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  const getContactEnums = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/contacts/enums'
      });
      setContactEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  const openDrawer = async (id) => {
    await getContactEnums();
    if (id) {
      setIdFromOverlay(id);
    }
    setPurpose(id ? 'edit' : 'create');
    setIsDrawerOpen(true);
  };

  const closeDrawer = (save) => {
    setPurpose(null);
    setIsDrawerOpen(false);
    setIdFromOverlay(null);
    if (save) setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const promises = [getEnums()];
        if (idCompany && !idFromOverlay) promises.push(getCompany());
        await Promise.all(promises);
      } catch (e) {
        if (isMounted) {
          message.error(e.message);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [idCompany]);

  return (
    <InsuranceCompanyContext.Provider
      value={{
        isLoading,
        company,
        enums,
        isFieldsLoading,
        idCompany,
        purpose,
        isDrawerOpen,
        openDrawer,
        closeDrawer,
        idFromOverlay,
        contactEnums,
        forceRefresh,
        setForceRefresh,
        totalResources,
        setTotalResources,
        fileLists,
        setFileLists,
        getCompany
      }}
    >
      {children}
    </InsuranceCompanyContext.Provider>
  );
};

InsuranceCompanyContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  idCompany: PropTypes.string
};

InsuranceCompanyContextProvider.defaultProps = {
  idCompany: undefined
};

export default () => useContext(InsuranceCompanyContext);
