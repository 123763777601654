// Main nav menuItems
export const routes = {
  HOME: '/',
  AGENCIES: '/agencies',
  USERS: '/users'
  // ACCESS_RIGHTS: '/access-rights' // Will be added later
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  CUSTOMERS: '?s=-created_at',
  INSURANCE_COMPANIES: '?s=-created_at',
  CONTACTS: '?s=-created_at'
};
