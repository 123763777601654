import {
  getPhoneNumber,
  getAddress,
  getFormattedDate
} from '../../utils/formatters';

/**
 * Use list content
 * @param {Object} data The agency data.
 * @returns {Array} The list content.
 */
export const useListContent = (data = {}) => {
  const translationKey = 'agencies.form';
  const {
    abbreviated_name,
    name,
    address,
    email,
    phone_number,
    siren_number,
    nic_number,
    company_creation_date,
    legal_form
  } = data;

  return [
    {
      label: `${translationKey}.abbreviated_name`,
      span: 1,
      content: abbreviated_name || '-'
    },
    {
      label: `${translationKey}.name`,
      span: 2,
      content: name || '-'
    },
    {
      label: `${translationKey}.siren_number`,
      span: 1,
      content: siren_number || '-'
    },
    {
      label: `${translationKey}.nic_number`,
      span: 1,
      content: nic_number || '-'
    },
    {
      label: `${translationKey}.company_creation_date`,
      span: 1,
      content: getFormattedDate(company_creation_date)
    },
    {
      label: `${translationKey}.address.address_full`,
      span: 1,
      content: getAddress(address) || '-'
    },
    {
      label: `${translationKey}.legal_form`,
      span: 2,
      content: legal_form || '-'
    },
    {
      label: `${translationKey}.email`,
      span: 1,
      content: email || '-'
    },
    {
      label: `${translationKey}.phone_number.number`,
      span: 2,
      content: getPhoneNumber(phone_number) || '-'
    }
  ];
};
