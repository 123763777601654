import { useCallback, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select, Radio, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;

/**
 * Hook to get contact fields
 * @hook
 * @param {string} purpose - Purpose of the form (create or edit)
 * @param {string} customerId - Id of the customer
 * @returns {Object} contact fields
 */
export const useFields = (purpose, customerId) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['position'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.positions || []).map((position) => (
            <Option key={position} value={position}>
              {t(`contacts.enums.${position}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['is_principal_contact'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['effective_beneficiary'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      input: <Input type="email" disabled={purpose === 'edit'} />
    },
    {
      label: 'phone_numbers',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Form.List name="phone_numbers" initialValue={[{}]}>
          {(phone_numbers, { add, remove }) => (
            <>
              {phone_numbers.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'phone_number']}
                    name={[field.name, 'phone_number']}
                    noLabel
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'country_code']}
                        initialValue="+33"
                      >
                        <Select>
                          <Option value="+33">+33</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item noStyle name={[field?.name, 'number']}>
                        <Input />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '-20px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('contacts.form.extra.add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['politically_exposed_person'],
      rules: [{ required: true }],
      initialValue: false,
      input: (
        <Radio.Group loading={isFieldsLoading}>
          {[true, false].map((bool) => (
            <Radio key={bool} value={bool}>
              {t(`contacts.enums.${bool}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    },
    {
      name: ['company'],
      initialValue: customerId,
      hidden: true,
      rules: [{ required: true }]
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
