import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { ConfigurationRouter } from '../routes/configuration/ConfigurationRouter';
import { CustomerRouter } from '../routes/customers/CustomerRouter';
import { DealsRouter } from '../routes/deals/DealsRouter';
import { InsuranceCompanyRouter } from '../routes/insurance_companies/InsuranceCompanyRouter';
import { UserRouter as UsrRouter } from '../routes/users/UserRouter';
import { UserTheme } from '../assets/styles/Theme/UserTheme';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const UserRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={`${routes.USERS}/*`} element={<UsrRouter />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.CUSTOMERS}/*`} element={<CustomerRouter />} />
          <Route path={`${routes.DEALS}/*`} element={<DealsRouter />} />
          <Route
            path={`${routes.INSURANCE_COMPANIES}/*`}
            element={<InsuranceCompanyRouter />}
          />
          <Route
            path={routes.CONFIGURATION}
            element={<ConfigurationRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
