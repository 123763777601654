import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Collapse, Badge, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns';
import { CreateUpdateContact } from './CreateUpdateContact';

/**
 * ListContacts component.
 *
 * @component
 * @param {string} customerId - customer id
 * @returns {JSX.Element} - The list of contacts.
 */
export const ListContacts = ({ customerId, customer }) => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const [purpose, setPurpose] = useState('create');
  const [totalResources, setTotalResources] = useState(0);

  const handleOpenDrawer = () => {
    const modalKey = Date.now();
    setDrawer(
      <CreateUpdateContact
        key={modalKey}
        setForceRefresh={setForceRefresh}
        customerId={customerId}
        purpose={purpose}
        setPurpose={setPurpose}
      />
    );
  };

  return (
    <>
      {drawer}
      <Collapse>
        {customer?.type === 'ENTITY' && (
          <Collapse.Panel
            header={
              <Flex align="center" gap={8}>
                <Typography.Title
                  level={4}
                  style={{
                    color: 'var(--userColor)',
                    margin: 0
                  }}
                  strong={false}
                >
                  {t('contacts.show.title')}
                </Typography.Title>
                <Badge
                  count={totalResources}
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    border: '1px solid #000000'
                  }}
                />
              </Flex>
            }
            key="contacts"
          >
            <ListResource
              resourceName="contacts"
              resourceModelName="Contact"
              extraQuery={`customer=${customerId}&archived=false`}
              columns={columns}
              forceRefresh={forceRefresh}
              withPageHeader={false}
              withSearchBar={false}
              withCreateButton={false}
              withUploadButton={false}
              onDoubleClickAction={false}
              showAction={false}
              editAction={false}
              contentCustomStyle={{ padding: 0 }}
              setTotalResources={setTotalResources}
            >
              <Flex justify="flex-end" style={{ width: '100%' }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setPurpose('create');
                    handleOpenDrawer();
                  }}
                >
                  {t('buttons.create')} <PlusOutlined />
                </Button>
              </Flex>
            </ListResource>
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
};

ListContacts.propTypes = {
  customerId: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    type: PropTypes.string
  }).isRequired
};
