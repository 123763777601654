export const headers = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'edi_code',
    key: 'edi_code'
  },
  {
    label: 'Phone number country code',
    key: 'phone_number.country_code'
  },
  {
    label: 'Phone number',
    key: 'phone_number.number'
  },
  {
    label: 'Address street number',
    key: 'address.number'
  },
  {
    label: 'Address street name',
    key: 'address.street'
  },
  {
    label: 'Address additional information',
    key: 'address.additional'
  },
  {
    label: 'Address postal code',
    key: 'address.postal_code'
  },
  {
    label: 'Address city',
    key: 'address.city'
  },
  {
    label: 'Address country',
    key: 'address.country'
  },
  {
    label: 'website',
    key: 'website'
  }
];
