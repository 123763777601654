import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, Tag, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Modal to add a new appointment subject
 * @param {*} setForceRefresh - function to force a refresh of the list of appointment subjects
 * @returns {JSX.Element} The modal to add a new appointment subject
 */
export const ModalAddSubject = ({ setForceRefresh }) => {
  const translationKey = 'appointment_subjects.form';
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const [open, setOpen] = useState(true);
  const [enums, setEnums] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      formData.append(
        'values',
        JSON.stringify({
          ...values,
          agency
        })
      );

      await dispatchAPI('POST', {
        url: 'appointment-subjects',
        body: formData
      });

      setOpen(false);
      setForceRefresh((prevState) => !prevState);
    } catch (error) {
      message(error);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/appointment-subjects/enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums();
      setIsFieldsLoading(false);
    })();
  }, []);

  return (
    <Modal
      title={t(`${translationKey}.title`)}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="label"
          label={t(`${translationKey}.label`)}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="types"
          label={t(`${translationKey}.types`)}
          rules={[{ required: true }]}
        >
          <Select
            loading={isFieldsLoading}
            mode="multiple"
            allowClear
            options={enums?.types?.map((type) => ({
              label: <Tag>{t(`${translationKey}.type.${type}`)}</Tag>,
              value: type
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="end" gap="small">
            <Button type="link" danger onClick={() => setOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddSubject.propTypes = {
  setForceRefresh: PropTypes.func.isRequired
};
