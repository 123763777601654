import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { DescriptionList } from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { useListContent } from '../../agencies/listContent';

/**
 * Agency configuration
 * @returns {JSX.Element} Agency configuration
 */
export const AgencyConfiguration = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, agency } = useAuthContext();
  const [agencyData, setAgencyData] = useState();
  const listContent = useListContent(agencyData);

  const getAgency = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/agencies/${agency}`
      });
      setAgencyData(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getAgency();
    })();
  }, [agency]);

  return !agencyData ? (
    <CustomSpin fullPage />
  ) : (
    <>
      <Divider orientation="left">
        {t('configuration.show.agency_information')}
      </Divider>
      <DescriptionList data={listContent} translate />
    </>
  );
};
