import { Route, Routes } from 'react-router-dom';
import { Configuration } from './Configuration';
import { Exception } from '../../components';

/**
 * Configuration Router
 *
 * Available routes:
 * - `/` - The configuration page
 * - `*` - The exception page.
 * @returns {JSX.Element} Configuration Router
 */
export const ConfigurationRouter = () => (
  <Routes>
    <Route index element={<Configuration />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
