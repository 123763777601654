import { useCallback, useEffect, useState } from 'react';
import { Input, Select, DatePicker, Tag, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { consultationStatus } from '../../../../utils/constants/tagColors';
import { dateFormat } from '../../../../utils/formatters';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Hook to get contact fields
 * @hook
 * @returns {Object} contact fields
 */
export const useFields = () => {
  const translationKey = 'consultations.form';
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/consultations/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/insurancecompany?archived=false'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await Promise.all([getEnums(), getCompanies()]);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(companies || []).map((company) => (
            <Option key={company._id} value={company._id}>
              {company.abbreviated_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['annual_amount'],
      input: <Input type="number" suffix="€" />
    },
    {
      name: ['entry_date'],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['submitted_to_company_date'],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['company_response_date'],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['expiry_date'],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={consultationStatus[status]}>
                {t(`${translationKey}.statuses.${status}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['presented_to_the_prospect'],
      valuePropName: 'checked',
      input: <Switch />
    },
    {
      name: ['additional_informations'],
      input: <TextArea rows={5} style={{ resize: 'none' }} />
    },
    {
      name: ['documents'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
