import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Custom component that returns a page header component with a custom title and extra element.
 *
 * @component
 * @param {string} title - The title of the page header.
 * @param {ReactElement} extra - The extra element to be displayed in the page header.
 * @returns {JSX.Element} The rendered page header component.
 */
export const PageHeaderCustom = ({ title, extra }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { pathname } = location;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  let tmpPath;
  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      tmpPath = `/${p}`;
      return {
        path: tmpPath,
        breadcrumbName: t(`breadcrumbs.${p}`)
      };
    });
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    },
    ...breadCrumbs
  ];

  const itemRender = (
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    isMounted && (
      <PageHeader
        breadcrumb={{ items: dynamicRoutes, itemRender }}
        title={title}
        onBack={() => navigate(-1)}
        ghost
        extra={extra}
      />
    )
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.element
};

PageHeaderCustom.defaultProps = {
  title: undefined,
  extra: null
};
