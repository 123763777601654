import React from 'react';
import { Button, Card, Col, Row, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FolderOpenFilled
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useDraggerProps } from './utils/draggerProps';
import { handleFileTypeChange } from './utils/handleFileTypeChange';
import { handleFileDelete } from './utils/handleFileDelete';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * `FilesManager` is a React component for managing a list of files. It allows for file deletion, viewing,
 * downloading, and type changing. It uses a custom Dragger component for file uploads.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {Array} props.filesList - Array of file objects.
 * @param {Function} props.setFilesList - Function to set the new file list.
 * @param {Array} props.filesKeys - Array of file keys.
 * @param {Array} props.filesConfiguration - The current configuration of the files.
 * @param {Function} props.setFilesConfiguration - Function to set the new files configuration.
 * @param {string} props.purpose - The purpose of the file usage.
 * @param {Function} props.deleteFile - Function to delete a specific file.
 * @returns {JSX.Element} A JSX element representing the component.
 */
const FilesManager = ({
  filesList,
  setFilesList,
  filesKeys,
  filesConfiguration,
  setFilesConfiguration,
  purpose,
  deleteFile
}) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const { message } = useErrorMessage();

  const draggerProps = useDraggerProps(filesList, setFilesList, message, t);

  return (
    <Card title={t('files.title')} className="file-manager">
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32
        }}
      >
        <Col span={16}>
          {filesList?.map((item) => (
            <Row
              key={item.name}
              justify="space-between"
              align="middle"
              gutter={[8, 8]}
              className="fileList-item"
            >
              <Col span={10} className="file-name">
                {item.name}
              </Col>
              <Col span={14}>
                <Row justify="end">
                  <Select
                    value={item.fileKey}
                    onChange={(value) =>
                      handleFileTypeChange(
                        item,
                        value,
                        filesConfiguration,
                        setFilesConfiguration
                      )
                    }
                    style={{ width: 240, marginRight: 8 }}
                    defaultValue={item?.fileKey}
                  >
                    {filesKeys.map((key) => (
                      <Option key={key} value={key}>
                        {t(`files.keys.${key}`)}
                      </Option>
                    ))}
                  </Select>
                  {purpose === 'edit' && item?.file && (
                    <>
                      <Button
                        onClick={() => viewDocument(item.file, 'show')}
                        type="link"
                        icon={<EyeOutlined />}
                      />
                      <Button
                        onClick={() => downloadDocument(item.file)}
                        type="link"
                        icon={<DownloadOutlined />}
                      />
                    </>
                  )}
                  <Button
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      handleFileDelete(
                        { name: item.name, id: item?._id },
                        deleteFile,
                        filesList,
                        setFilesList,
                        filesConfiguration,
                        setFilesConfiguration,
                        t
                      )
                    }
                  />
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
        <Col span={8}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <FolderOpenFilled />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Col>
      </Row>
    </Card>
  );
};

FilesManager.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filesKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  filesConfiguration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired
};

export default FilesManager;
