import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import useInsuranceCompanyContext from './InsuranceCompanyContext';
import { companiesStatus } from '../../utils/constants/tagColors';
import { getFullName, getPhoneNumber } from '../../utils/formatters';

/**
 * Hook to get columns for the insurance companies table
 * @hook
 * @returns {Array} Columns for the insurance companies table
 */
export const useColumns = () => {
  const translationKey = 'insurance_companies.form';
  const { t } = useTranslation();
  const { enums } = useInsuranceCompanyContext();

  return [
    {
      title: t(`${translationKey}.number`),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t(`${translationKey}.abbreviated_name`),
      key: 'abbreviated_name',
      dataIndex: 'abbreviated_name',
      sorter: true
    },
    {
      title: t(`${translationKey}.principal_contact`),
      key: 'principal_contact',
      dataIndex: 'principal_contact',
      render: (principal_contact) => getFullName(principal_contact)
    },
    {
      title: t(`${translationKey}.principal_contact_email`),
      key: 'principal_contact_email',
      dataIndex: 'principal_contact',
      render: (principal_contact) => principal_contact?.email || '-'
    },
    {
      title: t(`${translationKey}.principal_contact_phone_number`),
      key: 'principal_contact_phone_number',
      dataIndex: 'principal_contact',
      render: (principal_contact) =>
        getPhoneNumber(principal_contact?.phone_number)
    },
    {
      title: t(`${translationKey}.status`),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={companiesStatus[status]}>
            {t(`${translationKey}.statuses.${status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      filters: enums?.status?.map((s) => ({
        text: t(`${translationKey}.statuses.${s}`),
        value: s
      }))
    }
  ];
};
