import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Custom hook that returns an array of columns for the agencies table.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'agencies.form';

  return [
    {
      title: t(`${translationKey}.logo`),
      key: 'logo',
      dataIndex: 'logo'
    },
    {
      title: t(`${translationKey}.abbreviated_name`),
      key: 'abbreviated_name',
      dataIndex: 'abbreviated_name',
      sorter: true
    },
    { title: t(`${translationKey}.name`), key: 'name', dataIndex: 'name' },
    {
      title: t(`${translationKey}.siren_number`),
      key: 'siren_number',
      dataIndex: 'siren_number',
      sorter: true
    },
    {
      title: t(`${translationKey}.nic_number`),
      key: 'nic_number',
      dataIndex: 'nic_number'
    },
    { title: t(`${translationKey}.email`), key: 'email', dataIndex: 'email' },
    {
      title: t(`${translationKey}.phone_number.number`),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phoneNumber) => getPhoneNumber(phoneNumber)
    }
  ];
};
