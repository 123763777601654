import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, Tag, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Modal to add a new reason stage change
 * @param {function} setForceRefresh - function to set the force refresh state
 * @returns {JSX.Element} Modal to add a new reason stage change
 */
export const ModalAddReasonStageChange = ({ setForceRefresh }) => {
  const translationKey = 'reasons_stage_change.form';
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const [open, setOpen] = useState(true);
  const [enums, setEnums] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      formData.append(
        'values',
        JSON.stringify({
          ...values,
          agency
        })
      );

      await dispatchAPI('POST', {
        url: 'reason-stage-changes',
        body: formData
      });

      setOpen(false);
      setForceRefresh((prevState) => !prevState);
    } catch (error) {
      message(error);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/deals/enums'
      });
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums();
      setIsFieldsLoading(false);
    })();
  }, []);

  return (
    <Modal
      title={t(`${translationKey}.title`)}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="reason"
          label={t(`${translationKey}.reason`)}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="target_stages"
          label={t(`${translationKey}.target_stages`)}
          rules={[{ required: true }]}
        >
          <Select
            loading={isFieldsLoading}
            mode="multiple"
            allowClear
            options={enums?.stages?.map((stage) => ({
              label: <Tag>{t(`${translationKey}.target_stage.${stage}`)}</Tag>,
              value: stage
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setOpen(false)}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddReasonStageChange.propTypes = {
  setForceRefresh: PropTypes.func.isRequired
};
