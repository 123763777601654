import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { dateFormat } from '../../utils/formatters';

const { Option } = Select;

/**
 * Hook to get fields for agency form
 * @returns {Object} fields and isFieldsLoading
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/agencies/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['abbreviated_name'],
      rules: [{ required: true }]
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['siren_number'],
      rules: [{ required: true }]
    },
    {
      name: ['nic_number'],
      rules: [{ required: true }]
    },
    {
      name: ['company_creation_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['legal_form'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.legal_forms || []).map((legalForm) => (
            <Option key={legalForm} value={legalForm}>
              {legalForm}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      label: 'address.country',
      name: ['address', 'country'],
      input: (
        <Select loading={isFieldsLoading}>
          {['France'].map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['logo'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    },
    {
      name: ['signature'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
