import { useTranslation } from 'react-i18next';
import { Tag, Switch, Popconfirm, Divider } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useSuccessMessage } from '../../../../utils/successMessage';
import { consultationStatus } from '../../../../utils/constants/tagColors';
import {
  getFormattedAmount,
  getFormattedDate
} from '../../../../utils/formatters';

const iconSize = 18;

/**
 * Hook to get consultation columns
 * @hook
 * @param {function} setForceRefresh - function to set force refresh
 * @param {function} handleOpenDrawer - function to handle open drawer
 * @returns {Array} consultation columns
 */
export const useColumns = (setForceRefresh, handleOpenDrawer) => {
  const translationKey = 'consultations.col';
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();

  const patchConsultation = async (id, value) => {
    try {
      const formData = new FormData();
      formData.append(
        'values',
        JSON.stringify({
          presented_to_the_prospect: value
        })
      );

      await dispatchAPI('PATCH', {
        url: `/consultations/${id}`,
        body: formData
      });
      successMessage('consultation_updated');
    } catch (error) {
      message(error);
    }
  };

  const archiveConsultation = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/consultations/${id}` });
      successMessage('consultation_archived');
      setForceRefresh((prevState) => !prevState);
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      title: t(`${translationKey}.company`),
      key: 'company',
      dataIndex: 'company',
      render: (company) => company?.abbreviated_name || '-'
    },
    {
      title: t(`${translationKey}.annual_amount`),
      key: 'annual_amount',
      dataIndex: 'annual_amount',
      render: (annual_amount) => getFormattedAmount(annual_amount)
    },
    {
      title: t(`${translationKey}.entry_date`),
      key: 'entry_date',
      dataIndex: 'entry_date',
      render: (entry_date) => getFormattedDate(entry_date)
    },
    {
      title: t(`${translationKey}.submitted_to_company_date`),
      key: 'submitted_to_company_date',
      dataIndex: 'submitted_to_company_date',
      render: (submitted_to_company_date) =>
        getFormattedDate(submitted_to_company_date)
    },
    {
      title: t(`${translationKey}.company_response_date`),
      key: 'company_response_date',
      dataIndex: 'company_response_date',
      render: (company_response_date) => getFormattedDate(company_response_date)
    },
    {
      title: t(`${translationKey}.expiry_date`),
      key: 'expiry_date',
      dataIndex: 'expiry_date',
      render: (expiry_date) => getFormattedDate(expiry_date)
    },
    {
      title: t(`${translationKey}.status`),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={consultationStatus[status]}>
            {t(`${translationKey}.statuses.${status}`)}
          </Tag>
        ) : (
          'N/R'
        )
    },
    {
      title: t(`${translationKey}.presented_to_the_prospect`),
      key: 'presented_to_the_prospect',
      render: (record) => (
        <Switch
          checkedChildren={t('yes')}
          unCheckedChildren={t('no')}
          onChange={(checked) => patchConsultation(record?._id, checked)}
          defaultChecked={record?.presented_to_the_prospect}
        />
      )
    },
    {
      title: t(`${translationKey}.additional_informations`),
      dataIndex: 'additional_informations',
      key: 'additional_informations',
      render: (additional_informations) => additional_informations || '-'
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <EditOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              handleOpenDrawer('edit', record?._id);
            }}
          />
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() => archiveConsultation(record?._id)}
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: 'var(--errorColor)', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
