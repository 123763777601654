import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns';
import { CreateUpdateConsultation } from './CreateUpdateConsultation';

/**
 * ListConsultations component.
 *
 * @component
 * @param {string} dealId - deal id
 * @returns {JSX.Element} - The list of consultations.
 */
export const ListConsultations = ({ deal }) => {
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [drawer, setDrawer] = useState(null);

  const handleOpenDrawer = (purpose, resourceId) => {
    const modalKey = Date.now();
    setDrawer(
      <CreateUpdateConsultation
        key={modalKey}
        purpose={purpose}
        setForceRefresh={setForceRefresh}
        resourceId={resourceId}
      />
    );
  };

  return (
    <>
      {drawer}
      {deal?._id && (
        <ListResource
          resourceName="consultations"
          resourceModelName="Consultation"
          extraQuery={`deal=${deal?._id}&archived=false`}
          populate="company"
          columns={useColumns(setForceRefresh, handleOpenDrawer)}
          forceRefresh={forceRefresh}
          customActionColumn
          withPageHeader={false}
          withSearchBar={false}
          withCreateButton={false}
          withUploadButton={false}
          onDoubleClickAction={false}
          showAction={false}
          editAction={false}
          contentCustomStyle={{ padding: 0 }}
        >
          <Flex justify="flex-end" style={{ width: '100%' }}>
            <Button type="primary" onClick={() => handleOpenDrawer('create')}>
              {t('buttons.create')} <PlusOutlined />
            </Button>
          </Flex>
        </ListResource>
      )}
    </>
  );
};

ListConsultations.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
