import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  getFormattedAmount,
  getFormattedDate
} from '../../../utils/formatters';

const { Option } = Select;

/**
 * Hook to get consultations fields
 * @hook
 * @param {string} dealId - ID of the deal
 * @returns {object} consultations fields
 */
export const useFields = (dealId) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [consultations, setConsultations] = useState([]);

  const getConsultations = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/consultations?deal=${dealId}&status=VALIDATED&populate=company`
      });
      setConsultations(data);
    } catch (error) {
      message(error);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getConsultations();
    })();
  }, [dealId]);

  const fields = [
    {
      label: 'validated_consultations',
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(consultations || []).map((consultation) => (
            <Option key={consultation._id} value={consultation._id}>
              {`${
                consultation?.company?.abbreviated_name
              } - ${getFormattedAmount(
                consultation?.annual_amount
              )} - ${getFormattedDate(consultation?.entry_date)}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['idd_file'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  return { fields };
};
