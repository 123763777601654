import { useTranslation } from 'react-i18next';
import { Col, Collapse, Badge, Flex } from 'antd';
import useInsuranceCompanyContext from './InsuranceCompanyContext';
import { ListResource } from '../../components';
import { useColumns } from './contactsColumns';
import { routes } from '../../utils/constants/adminRoutes';
import { contactsHeaders } from './contactsHeaders';

export const ListContacts = () => {
  const { t } = useTranslation();
  const {
    openDrawer,
    idCompany,
    forceRefresh,
    totalResources,
    setTotalResources
  } = useInsuranceCompanyContext();
  const columns = useColumns();

  const items = [
    {
      key: '1',
      forceRender: true,
      label: (
        <Flex gap={8}>
          {t('contacts.show.title')}
          <Badge count={totalResources} />
        </Flex>
      ),
      children: (
        <ListResource
          contentCustomStyle={{ padding: 0, margin: 0 }}
          resourceName="contacts"
          columns={columns}
          resourceModelName="Contact"
          withImportButton={false}
          withPageHeader={false}
          onDoubleClickAction={false}
          extraQuery={`company=${idCompany}&archived=${false}`}
          showAction={{
            pathname: (record) => `${routes.CONTACTS}/show/${record._id}`
          }}
          headers={contactsHeaders}
          openOverlay={openDrawer}
          forceRefresh={forceRefresh}
          setTotalResources={setTotalResources}
        />
      )
    }
  ];

  return (
    <Col span="24">
      <Collapse items={items} />
    </Col>
  );
};
