import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps, Flex, Spin } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ModalChangeStage } from '../Modals/ModalChangeStage';
import { ModalDealWin } from '../Modals/ModalDealWin';
import { ContentCustom } from '../../../components';

/**
 * DealStepper
 *
 * @description This component displays the deal stepper based on the current stage of the deal. The user can change the stage of the deal by clicking on the stepper.
 *
 * @component
 * @param {string} currentStage - Current stage of the deal
 * @param {function} setForceRefresh - Function to set the force refresh state
 * @param {string} dealId - ID of the deal
 * @returns {JSX.Element} DealStepper component
 */
export const DealStepper = ({ currentStage, setForceRefresh, dealId }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);

  const items = (enums?.stages_with_percentage || []).map((stage) => ({
    title: t(`deals.form.stages.${stage.stage}`),
    description: `${stage.value} %`
  }));

  const getEnums = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/deals/enums`
      });
      setEnums(data);
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const onChange = (value) => {
    const newStage = enums.stages[value];
    const modalKey = Date.now();
    if (newStage === 'DEAL_WON') {
      setModal(
        <ModalDealWin
          key={modalKey}
          deal_id={dealId}
          new_stage={newStage}
          previous_stage={currentStage}
          setForceRefresh={setForceRefresh}
        />
      );
    } else {
      setModal(
        <ModalChangeStage
          key={modalKey}
          deal_id={dealId}
          new_stage={newStage}
          previous_stage={currentStage}
          setForceRefresh={setForceRefresh}
        />
      );
    }
  };

  return isLoading ? (
    <Flex justify="center" align="center" style={{ width: '100vw' }}>
      <Spin />
    </Flex>
  ) : (
    <ContentCustom>
      {modal}
      <Steps
        progressDot
        labelPlacement="vertical"
        current={(enums?.stages || []).indexOf(currentStage)}
        onChange={onChange}
        items={items}
        size="small"
      />
    </ContentCustom>
  );
};

DealStepper.propTypes = {
  currentStage: PropTypes.string,
  setForceRefresh: PropTypes.func.isRequired,
  dealId: PropTypes.string.isRequired
};

DealStepper.defaultProps = {
  currentStage: undefined
};
