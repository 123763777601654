import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

/**
 * Columns for the reasons stage change table
 * @returns {Array} columns for the reasons stage change table
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'reasons_stage_change.form';

  return [
    {
      title: t(`${translationKey}.reason`),
      key: 'reason',
      dataIndex: 'reason',
      sorter: true
    },
    {
      title: t(`${translationKey}.target_stages`),
      key: 'target_stages',
      dataIndex: 'target_stages',
      render: (target_stages) =>
        target_stages.map((target_stage) => (
          <Tag>{t(`${translationKey}.target_stage.${target_stage}`)}</Tag>
        ))
    }
  ];
};
