import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag, Flex } from 'antd';
import { CrownOutlined, WarningOutlined } from '@ant-design/icons';
import { getFullName } from '../../../utils/formatters';

/**
 * ShowTitle
 * @component
 * @returns {JSX.Element} Component to display the title of the customer
 */
export const ShowTitle = ({ customer }) => {
  const { t } = useTranslation();
  return (
    <Flex align="center">
      <span className="page-header-title">
        {`${customer?.number} - 
        ${
          customer?.first_name && customer?.last_name
            ? getFullName(customer)
            : customer?.name
        }`}
      </span>
      <Tag>
        {customer?.prospect
          ? t('customers.form.prospect')
          : t('customers.form.assured')}
      </Tag>
      {customer?.vip ? (
        <Tag color="gold">
          <CrownOutlined /> VIP
        </Tag>
      ) : (
        ''
      )}
      {customer?.red_list ? (
        <Tag color="volcano">
          <WarningOutlined /> {t('customers.form.red_list')}
        </Tag>
      ) : (
        ''
      )}
    </Flex>
  );
};

ShowTitle.propTypes = {
  customer: PropTypes.shape({
    number: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    name: PropTypes.string,
    prospect: PropTypes.bool,
    vip: PropTypes.bool,
    red_list: PropTypes.bool
  })
};

ShowTitle.defaultProps = {
  customer: undefined
};
