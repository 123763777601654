import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import { Popconfirm, Button, Card, Flex, Tooltip } from 'antd';
import {
  EyeOutlined,
  ContainerOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Star, StarFilled } from '@carbon/icons-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useSuccessMessage } from '../../../utils/successMessage';
import {
  getCustomerName,
  getFullName,
  getFormattedDate
} from '../../../utils/formatters';
import { ModalChangeStage } from '../Modals/ModalChangeStage';
import { ModalDealWin } from '../Modals/ModalDealWin';

/**
 * DealCard
 * @component
 * @param {function} handleEditDeal - function to handle edit deal
 * @param {function} archiveDeal - function to archive deal
 * @param {object} deal - deal object
 * @param {string} currentStage - current stage
 * @param {function} setForceRefresh - function to set force refresh
 * @returns {JSX.Element} DealCard component
 */
export const DealCard = ({
  handleEditDeal,
  archiveDeal,
  deal,
  currentStage,
  setForceRefresh
}) => {
  const {
    _id,
    name,
    customer,
    principal_contact,
    stage,
    updated_at,
    favorite
  } = deal;
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const { pathname } = useLocation();
  const [modal, setModal] = useState(null);
  const [isFavorite, setIsFavorite] = useState(favorite);

  const handleFavorites = async (method) => {
    try {
      const formData = new FormData();
      formData.append(
        'values',
        JSON.stringify({ [method]: { favorites: user?._id } })
      );

      await dispatchAPI('PATCH', {
        url: `/deals/${_id}`,
        body: formData
      });

      setIsFavorite(!isFavorite);
      successMessage(
        method === '$push'
          ? 'deal_added_to_favorites'
          : 'deal_removed_from_favorites'
      );
    } catch (error) {
      message(error);
    }
  };

  const handleDealDrop = (new_stage) => {
    const modalKey = Date.now();
    if (new_stage === 'DEAL_WON') {
      setModal(
        <ModalDealWin
          key={modalKey}
          deal_id={_id}
          new_stage={new_stage}
          previous_stage={stage}
          setForceRefresh={setForceRefresh}
        />
      );
    } else {
      setModal(
        <ModalChangeStage
          key={modalKey}
          deal_id={_id}
          new_stage={new_stage}
          previous_stage={stage}
          setForceRefresh={setForceRefresh}
        />
      );
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'DEAL',
    item: { _id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (dropResult.target.stage !== currentStage) {
          handleDealDrop(dropResult.target.stage);
        }
      }
    }
  });

  return (
    <>
      {modal}
      <Card
        className="kanban-card"
        ref={drag}
        style={{ opacity: isDragging ? '0.5' : '1' }}
        title={name}
      >
        <Flex align="center" gap="small" wrap>
          <span>{customer && getCustomerName(customer)}</span>
          {principal_contact && (
            <>
              •
              <span>{principal_contact && getFullName(principal_contact)}</span>
            </>
          )}
        </Flex>
        <Flex
          justify="space-between"
          align="center"
          className="kanban-card-meta"
        >
          <span className="kanban-card-date">
            {t('deals.updated_at', { date: getFormattedDate(updated_at) })}
          </span>
          <Flex className="kanban-card-actions">
            <Tooltip
              title={
                isFavorite
                  ? t('deals.remove_from_favorites')
                  : t('deals.add_to_favorites')
              }
            >
              <Button
                type="link"
                onClick={() => handleFavorites(isFavorite ? '$pull' : '$push')}
                className="no-padding no-background"
              >
                {isFavorite ? <StarFilled /> : <Star />}
              </Button>
            </Tooltip>

            <Link
              to={{
                pathname: `${pathname}/show/${_id}`
              }}
            >
              <EyeOutlined />
            </Link>

            <EditOutlined
              onClick={() => {
                handleEditDeal(deal);
              }}
            />

            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => {
                archiveDeal(_id);
              }}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: 'var(--errorColor)' }}
                type="delete"
              />
            </Popconfirm>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

DealCard.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  currentStage: PropTypes.string.isRequired,
  handleEditDeal: PropTypes.func.isRequired,
  archiveDeal: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    _id: PropTypes.string,
    favorite: PropTypes.bool,
    name: PropTypes.string,
    stage: PropTypes.string,
    updated_at: PropTypes.string,
    principal_contact: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    lead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    customer: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }).isRequired
};
