import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select, Radio, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { dateFormat } from '../../../../utils/formatters';

const { Option } = Select;

/**
 * Hook to get customer fields
 * @hook
 * @returns {Object} customer fields
 */
export const useFields = () => {
  const translationKey = 'customers.form';
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [customerType, setCustomerType] = useState('ENTITY');
  const [insuredAs, setInsuredAs] = useState('COMPANY');

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      initialValue: customerType,
      input: (
        <Radio.Group
          loading={isFieldsLoading}
          onChange={(e) => setCustomerType(e.target.value)}
        >
          {(enums?.types || []).map((type) => (
            <Radio key={type} value={type}>
              {t(`${translationKey}.types.${type}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    },
    ...(customerType === 'PHYSICAL'
      ? [
          {
            name: ['last_name'],
            rules: [{ required: true }]
          },
          {
            name: ['first_name'],
            rules: [{ required: true }]
          },
          {
            name: ['insured_as'],
            rules: [{ required: true }],
            initialValue: insuredAs,
            input: (
              <Radio.Group
                loading={isFieldsLoading}
                onChange={(e) => setInsuredAs(e.target.value)}
              >
                {(enums?.insured_as || []).map((as) => (
                  <Radio key={as} value={as}>
                    {t(`${translationKey}.insured_as_list.${as}`)}
                  </Radio>
                ))}
              </Radio.Group>
            )
          },
          ...(insuredAs === 'COMPANY'
            ? [
                {
                  name: ['company_creation_date'],
                  input: <DatePicker format={dateFormat} />
                },
                {
                  name: ['siren_number'],
                  rules: [{ required: true }]
                },
                {
                  name: ['nic_number']
                },
                {
                  name: ['legal_form'],
                  rules: [{ required: true }],
                  input: (
                    <Select loading={isFieldsLoading}>
                      {(enums?.legal_forms || []).map((legalForm) => (
                        <Option key={legalForm} value={legalForm}>
                          {t(`${translationKey}.legal_forms.${legalForm}`)}
                        </Option>
                      ))}
                    </Select>
                  )
                },
                {
                  name: ['turnover'],
                  input: <Input type="number" />
                }
              ]
            : [])
        ]
      : []),
    ...(customerType === 'ENTITY'
      ? [
          {
            name: ['name'],
            rules: [{ required: true }]
          },
          {
            name: ['siren_number'],
            rules: [{ required: true }]
          },
          {
            name: ['nic_number'],
            rules: [{ required: true }]
          },
          {
            name: ['company_creation_date'],
            rules: [{ required: true }],
            input: <DatePicker format={dateFormat} />
          },
          {
            name: ['legal_form'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums?.legal_forms || []).map((legalForm) => (
                  <Option key={legalForm} value={legalForm}>
                    {t(`${translationKey}.legal_forms.${legalForm}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['staff'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums?.staff || []).map((staff) => (
                  <Option key={staff} value={staff}>
                    {t(`${translationKey}.staff_list.${staff}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['can_contain_entities'],
            valuePropName: 'checked',
            input: <Checkbox />
          }
        ]
      : []),
    ...(customerType === 'PHYSICAL'
      ? [
          {
            name: ['politically_exposed_person'],
            rules: [{ required: true }],
            input: (
              <Radio.Group>
                <Radio value>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            )
          }
        ]
      : []),
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }]
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      label: 'address.city',
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      label: 'address.country',
      name: ['address', 'country'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {['France'].map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['vip'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['red_list'],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
