import { useState, useEffect } from 'react';
import { DatePicker, Select, Input } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { dateFormat, getFullName } from '../../../../../../utils/formatters';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Hook to get contacts fields
 * @hook
 * @param {string} customerId - ID of the customer
 * @returns {object} contacts fields
 */
export const useFields = (customerId) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [contacts, setContacts] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getCustomerContacts = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/contacts?customer=${customerId}&archived=false`
      });
      setContacts(data);
    } catch (error) {
      message(error);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomerContacts();
    })();
  }, [customerId]);

  const fields = [
    {
      name: ['contact'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {contacts.map((contact) => (
            <Option key={contact._id} value={contact._id}>
              {getFullName(contact)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'date_and_hour',
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          showTime={{
            format: 'HH:mm'
          }}
          format={`${dateFormat} HH:mm`}
        />
      )
    },
    {
      name: ['comment'],
      input: <TextArea rows={5} style={{ resize: 'none' }} />
    }
  ];

  return { fields };
};
