import { MailOutlined } from '@ant-design/icons';
import {
  getPhoneNumber,
  getAddress,
  getFormattedDate,
  getFullName
} from '../../../utils/formatters';

/**
 * Hook to get list content
 * @hook
 * @param {Object} data - data to use
 * @returns {Object} - list content
 */
export const useListContent = (data = {}) => {
  const translationKey = 'customers.form';
  const {
    name,
    type,
    address,
    email,
    phone_number,
    siren_number,
    nic_number,
    company_creation_date,
    legal_form,
    turnover,
    staff,
    can_contain_entities,
    agency,
    client_relationship_manager,
    primary_placement_agent,
    secondary_placement_agent
  } = data;

  const principaleInformations = [
    {
      label: `${translationKey}.type`,
      span: 1,
      content: type || '-'
    },
    {
      label: `${translationKey}.name`,
      span: 1,
      content: name || '-'
    },
    {
      label: `${translationKey}.company_creation_date`,
      span: 1,
      content: getFormattedDate(company_creation_date)
    },
    {
      label: `${translationKey}.siren_number`,
      span: 1,
      content: siren_number || '-'
    },
    {
      label: `${translationKey}.nic_number`,
      span: 1,
      content: nic_number || '-'
    },
    {
      label: `${translationKey}.legal_form`,
      span: 2,
      content: legal_form || '-'
    },
    {
      label: `${translationKey}.turnover`,
      span: 1,
      content: turnover || '-'
    },
    {
      label: `${translationKey}.staff`,
      span: 2,
      content: staff || '-'
    },
    {
      label: `${translationKey}.address.address_full`,
      span: 1,
      content: getAddress(address) || '-'
    },
    {
      label: `${translationKey}.email`,
      span: 1,
      content:
        (
          <>
            <MailOutlined style={{ marginRight: 10 }} />
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </>
        ) || '-'
    },
    {
      label: `${translationKey}.phone_number.number`,
      span: 2,
      content: getPhoneNumber(phone_number) || '-'
    },
    {
      label: `${translationKey}.can_contain_entities`,
      span: 1,
      content: can_contain_entities ? 'Oui' : 'Non'
    }
  ];

  const managementInformations = [
    {
      label: `${translationKey}.agency`,
      span: 1,
      content: agency?.abbreviated_name || '-'
    },
    {
      label: `${translationKey}.client_relationship_manager`,
      span: 2,
      content: getFullName(client_relationship_manager)
    },
    {
      label: `${translationKey}.primary_placement_agent`,
      span: 1,
      content: getFullName(primary_placement_agent)
    },
    {
      label: `${translationKey}.secondary_placement_agent`,
      span: 2,
      content: getFullName(secondary_placement_agent)
    }
  ];

  return { principaleInformations, managementInformations };
};
