import IBAN from 'iban';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that provides fields configuration for bank informations forms.
 *
 * @hook
 * @returns {object} - An object containing the fields configuration.
 */
export const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      name: ['account_holder'],
      rules: [{ required: true }]
    },
    {
      name: ['rum'],
      rules: [{ required: true }]
    },
    {
      name: ['iban'],
      hasFeedback: IBAN,
      rules: [
        { required: true },
        () => ({
          validator(_, value) {
            if (IBAN.isValid(value)) return Promise.resolve();
            return Promise.reject(t('errors.message.invalid_IBAN'));
          }
        })
      ]
    },
    {
      name: ['bic'],
      rules: [{ required: true }]
    },
    {
      name: ['ics'],
      rules: [{ required: true }]
    },
    {
      name: ['_id'],
      hidden: true
    }
  ];

  return {
    fields
  };
};
