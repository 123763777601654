import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, Flex, Form, Button } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * Create or update a contact
 * @component
 * @prop {function} setForceRefresh - function to set force refresh
 * @prop {string} customerId - customer id
 * @returns {JSX.Element} The form to create or update a contact
 */
export const CreateUpdateContact = ({
  setForceRefresh,
  customerId,
  purpose,
  setPurpose
}) => {
  const translationKey = 'contacts.form';
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const [open, setOpen] = useState(true);
  const { fields } = useFields(purpose, customerId);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      formData.append(
        'values',
        JSON.stringify({
          ...values,
          agency,
          customer: customerId || id
        })
      );

      await dispatchAPI('POST', {
        url: '/contacts',
        body: formData
      });
      setOpen(false);
      setPurpose('create');
      setForceRefresh((prevState) => !prevState);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Drawer
      title={t(`${translationKey}.title.${purpose}`)}
      open={open}
      onClose={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" onFinish={onFinish}>
        {fields.map((field) => generateFormItem('contacts', field))}
        <Form.Item>
          <Flex justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setOpen(false)}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

CreateUpdateContact.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  purpose: PropTypes.string,
  setPurpose: PropTypes.func.isRequired
};

CreateUpdateContact.defaultProps = {
  customerId: undefined,
  purpose: null
};
