import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Datatable } from '../../../components';
import { useColumns } from './columns';
import { ModalAddReasonStageChange } from './ModalAddReasonStageChange';

/**
 * List Reason Stage Change
 * @returns {JSX.Element} Reason Stage Change List
 */
export const ListReasonStageChange = () => {
  const { t } = useTranslation();
  const { agency } = useAuthContext();
  const columns = useColumns();
  const [modal, setModal] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  const handleOpenModal = () => {
    const modalKey = Date.now();
    setModal(
      <ModalAddReasonStageChange
        key={modalKey}
        setForceRefresh={setForceRefresh}
      />
    );
  };

  return (
    <>
      {modal}
      <Datatable
        resourceName="reason-stage-changes"
        extraQuery={`agency=${agency}&archived=false`}
        columns={columns}
        forceRefresh={forceRefresh}
        showAction={false}
        editAction={false}
        scroll={{ x: 600 }}
      />
      <Button
        style={{ width: '100%' }}
        type="dashed"
        onClick={() => handleOpenModal()}
      >
        {t('buttons.add_line')}
      </Button>
    </>
  );
};
