export const contactsHeaders = [
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'position',
    key: 'position'
  },
  {
    label: 'is_principal_contact',
    key: 'is_principal_contact'
  },
  {
    label: '',
    key: 'effective_beneficiary'
  },
  {
    label: 'politically_exposed_person',
    key: 'politically_exposed_person'
  }
];
