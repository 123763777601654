import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Drawer, Form, Button, Flex } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useSuccessMessage } from '../../../utils/successMessage';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * The create or update deal drawer
 * @component
 * @param {string} purpose - The purpose of the drawer
 * @param {object} initialValues - The initial values of the form
 * @param {function} setForceRefresh - The function to set the force refresh
 * @returns {JSX.Element} The create or update deal drawer
 */
export const CreateUpdateDealDrawer = ({
  purpose,
  initialValues,
  setForceRefresh
}) => {
  const translationKey = 'deals.form';
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI, agency } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const generateFormItem = useGenerateFormItem();
  const { fields } = useFields(purpose, initialValues);
  const [open, setOpen] = useState(true);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      formData.append(
        'values',
        JSON.stringify({
          ...values,
          agency
        })
      );

      await dispatchAPI(purpose === 'create' ? 'POST' : 'PATCH', {
        url: `/deals${purpose === 'create' ? '' : `/${initialValues._id}`}`,
        body: formData
      });

      setOpen(false);
      setForceRefresh((prevState) => !prevState);
      successMessage(
        purpose === 'create' ? t('deal_created') : t('deal_updated')
      );
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        customer: initialValues?.customer?._id,
        principal_contact: initialValues?.principal_contact?._id,
        internal_deadline: initialValues?.internal_deadline
          ? dayjs(initialValues?.internal_deadline)
          : null,
        customer_deadline: initialValues?.customer_deadline
          ? dayjs(initialValues?.customer_deadline)
          : null
      });
    }
  }, [initialValues]);

  return (
    <Drawer
      title={t(`${translationKey}.title.${purpose}`)}
      open={open}
      onClose={() => setOpen(false)}
      footer={null}
      width={480}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {fields.map((field) => generateFormItem('deals', field))}
        <Form.Item>
          <Flex justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setOpen(false)}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

CreateUpdateDealDrawer.propTypes = {
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    customer: PropTypes.string,
    stage: PropTypes.string,
    deal_manager: PropTypes.string,
    internal_deadline: PropTypes.string,
    customer_deadline: PropTypes.string,
    amount: PropTypes.number,
    replacement_order: PropTypes.string,
    deal_origin: PropTypes.string,
    description: PropTypes.string,
    principal_contact: PropTypes.string,
    decision_maker: PropTypes.string,
    reason_stage_change: PropTypes.string,
    deals_origin: PropTypes.string
  }),
  purpose: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

CreateUpdateDealDrawer.defaultProps = {
  initialValues: null
};
