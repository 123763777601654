import { Form, Input, Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import useInsuranceCompanyContext from './InsuranceCompanyContext';

const { Option } = Select;

/**
 * Hook to get insurance company fields
 * @hook
 * @returns {object} insurance company fields
 */
export const useFields = () => {
  const translationKey = 'insurance_companies.form';
  const { t } = useTranslation();
  const { enums, isFieldsLoading } = useInsuranceCompanyContext();

  const fields = [
    {
      name: ['abbreviated_name'],
      rules: [{ required: true }]
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['edi_code'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.edi_codes || []).map((edi_code) => (
            <Option key={edi_code} value={edi_code}>
              {edi_code}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      label: 'address.country',
      name: ['address', 'country'],
      input: (
        <Select loading={isFieldsLoading}>
          {['France'].map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['website'],
      rules: [
        {
          message: t('errors.form.URL'),
          pattern: /^https:\/\/.+$/
        }
      ]
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      initialValue: 'ACTIVE',
      input: (
        <Radio.Group loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Radio key={status} value={status}>
              {t(`${translationKey}.statuses.${status}`)}
            </Radio>
          ))}
        </Radio.Group>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
