import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Flex, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { routes } from '../../../utils/constants/userRoutes';
import { ShowTitle } from './ShowTitle';
import { ListContacts } from './contacts/ListContacts';
import FilesManagerShow from '../../../components/CreateUpdateContainer/FilesManager/FilesManagerShow';

/**
 * ShowCustomer
 * @component
 * @returns {JSX.Element} Component to display the customer
 */
export const ShowCustomer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [fileLists, setFileLists] = useState({});
  const { principaleInformations, managementInformations } =
    useListContent(customer);

  const getCustomer = useCallback(async () => {
    try {
      setIsLoading(true);

      const population = [
        'agency',
        'client_relationship_manager',
        'primary_placement_agent',
        'secondary_placement_agent',
        'kbis_files',
        'status_files',
        'directors_id_files',
        'beneficiary_id_files',
        'rib_files',
        'other_files'
      ];

      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}?populate=${population.join(',')}`
      });
      setCustomer(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCustomer();
    })();
  }, [getCustomer]);

  const deleteAgency = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/customers/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return isLoading ? (
    <CustomSpin fullPage />
  ) : (
    <>
      <PageHeaderCustom
        title={customer && <ShowTitle customer={customer} />}
        extra={
          <>
            <Link to={{ pathname: `${routes.CUSTOMERS}/edit/${id}` }}>
              <Button type="primary">
                {t('buttons.edit')}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={deleteAgency}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {t('buttons.archive')}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={16}>
                <Card title={t('customers.show.titles.main_informations')}>
                  <DescriptionList
                    layout="vertical"
                    data={principaleInformations}
                    translate
                  />
                </Card>
              </Col>
              <Col xs={24} xl={8}>
                <Card title={t('customers.show.titles.managment')}>
                  <DescriptionList
                    layout="vertical"
                    data={managementInformations}
                    translate
                  />
                </Card>
              </Col>
            </Row>
          </Skeleton>
          <ListContacts customerId={id} customer={customer} />
          <FilesManagerShow
            resourceName="customers"
            tradKey="customers"
            fileKeys={[
              'kbis_files',
              'status_files',
              'directors_id_files',
              'beneficiary_id_files',
              'rib_files',
              'other_files'
            ]}
            fileLists={fileLists}
            setFileLists={setFileLists}
            resource={customer}
            getResource={getCustomer}
          />
        </Flex>
      </ContentCustom>
    </>
  );
};
