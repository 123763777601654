import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

/**
 * Appointment subjects columns
 * @returns {*[]} Appointment subjects columns
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'appointment_subjects.form';

  return [
    {
      title: t(`${translationKey}.label`),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t(`${translationKey}.types`),
      key: 'types',
      dataIndex: 'types',
      render: (types) =>
        types.map((type) => <Tag>{t(`${translationKey}.type.${type}`)}</Tag>)
    }
  ];
};
