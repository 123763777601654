import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Flex } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { KanbanColumn } from './KanbanColumn';

/**
 * KanbanBoard component
 *
 * @component
 * @param {object} props - component props
 * @param {function} props.handleEditDeal - function to handle edit deal
 * @param {function} props.archiveDeal - function to archive deal
 * @param {string} props.keywords - search keywords
 * @param {object} props.extraQuery - extra query filters
 * @param {boolean} props.forceRefresh - force refresh
 * @param {function} props.setForceRefresh - set force refresh
 * @returns {JSX.Element} KanbanBoard component
 */
export const KanbanBoard = ({
  handleEditDeal,
  archiveDeal,
  keywords,
  extraQuery,
  forceRefresh,
  setForceRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const getColumns = async () => {
    try {
      setIsLoading(true);

      const searchURL = keywords
        ? `/search/${encodeURIComponent(keywords)}`
        : null;

      const url = `/deals/kanban${searchURL || ''}?${extraQuery}`;

      const { data } = await dispatchAPI('GET', { url });

      setColumns(data);
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getColumns();
    })();
  }, [forceRefresh, keywords, extraQuery]);

  return isLoading ? (
    <CustomSpin fullPage />
  ) : (
    <DndProvider backend={HTML5Backend}>
      <Flex className="kanban-scroll deals">
        {columns?.map((column) => (
          <KanbanColumn
            handleEditDeal={handleEditDeal}
            archiveDeal={archiveDeal}
            column={column}
            key={column.stage}
            setForceRefresh={setForceRefresh}
          />
        ))}
      </Flex>
    </DndProvider>
  );
};

KanbanBoard.propTypes = {
  handleEditDeal: PropTypes.func.isRequired,
  archiveDeal: PropTypes.func.isRequired,
  keywords: PropTypes.string,
  extraQuery: PropTypes.string.isRequired,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func.isRequired
};

KanbanBoard.defaultProps = {
  keywords: null,
  forceRefresh: false
};
