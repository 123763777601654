import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { CheckmarkFilled, CloseOutline } from '@carbon/icons-react';
import { MailOutlined } from '@ant-design/icons';
import { getPhoneNumber } from '../../../../utils/formatters';

/**
 * Columns for the contacts table
 * @hook
 * @returns {Array} Columns for the contacts table
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const translationKey = 'contacts.form';

  return [
    {
      title: t(`${translationKey}.last_name`),
      key: 'last_name',
      dataIndex: 'last_name'
    },
    {
      title: t(`${translationKey}.first_name`),
      key: 'first_name',
      dataIndex: 'first_name'
    },
    {
      title: t(`${translationKey}.position`),
      key: 'position',
      dataIndex: 'position'
    },
    {
      title: t(`${translationKey}.is_principal_contact`),
      key: 'is_principal_contact',
      dataIndex: 'is_principal_contact',
      width: 90,
      render: (is_principal_contact) =>
        is_principal_contact ? (
          <CheckmarkFilled color="var(--successColor)" size={18} />
        ) : (
          <CloseOutline color="var(--errorColor)" size={18} />
        )
    },
    {
      title: t(`${translationKey}.effective_beneficiary`),
      key: 'effective_beneficiary',
      dataIndex: 'effective_beneficiary',
      width: 110,
      render: (effective_beneficiary) =>
        effective_beneficiary ? (
          <CheckmarkFilled color="var(--successColor)" size={18} />
        ) : (
          <CloseOutline color="var(--errorColor)" size={18} />
        )
    },
    {
      title: t(`${translationKey}.email`),
      key: 'email',
      dataIndex: 'email',
      render: (email) =>
        email ? (
          <>
            <MailOutlined style={{ marginRight: 10 }} />
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </>
        ) : (
          '-'
        )
    },
    {
      title: t(`${translationKey}.phone_numbers`),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => getPhoneNumber(phone_number)
    },
    {
      title: t(`${translationKey}.conformity`),
      key: 'conformity',
      dataIndex: 'politically_exposed_person',
      width: 160,
      render: (politically_exposed_person) => (
        <Tag color={politically_exposed_person ? 'red' : 'green'}>
          {politically_exposed_person
            ? 'Politiquement exposé'
            : 'Non politiquement exposé'}
        </Tag>
      )
    }
  ];
};
